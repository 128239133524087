<template>
  <div>
    <v-file-input
      outlined
      dense
      prepend-inner-icon="mdi-music-note"
      prepend-icon=""
      multiple
      accept="audio/mpeg"
      :clearable="false"
      v-model="sounds"
      @change="onChange"
      :placeholder="`${soundPreviews.length} Ήχ${
        soundPreviews.length == 1 ? 'ος' : 'οι'
      }`"
      hint="Αρχεία MP3 μέχρι και 8MB ανά αρχείο"
      :error-messages="soundsErrors"
      persistent-hint
      ref="sounds"
      class="mb-1"
      id="soundInput"
    >
    </v-file-input>

    <v-row class="mb-5">
      <v-col cols="3">
        <div class="file-wrapper rounded">
          <label
            class="
              d-flex
              flex-column
              align-center
              justify-center
              add-file
              grey
              lighten-4
              pa-2
            "
            for="soundInput"
          >
            <v-icon color="primary" class="mb-2">mdi-plus</v-icon>
            <div class="body-2 primary--text text-center">Προσθήκη Ήχου</div>
          </label>
        </div>
      </v-col>

      <v-col cols="3" v-for="(sound, index) in soundPreviews" :key="index">
        <div class="file-wrapper rounded">
          <div
            class="
              file-overlay
              d-flex
              flex-column
              align-center
              justify-center
              rounded
              grey
              lighten-4
              pa-2
              primary--text
            "
          >
            <v-tooltip top v-if="sound.sound">
              <template v-slot:activator="{ on, attrs }">
                <div class="body-2 text-center dotted" v-bind="attrs" v-on="on">
                  {{ sound.sound.name }}
                </div>
              </template>

              <span>{{ sound.sound.name }}</span>
            </v-tooltip>

            <div class="body-2 font-weight-bold" v-if="sound.sound">
              {{ sound.size ? sound.size : "" }}
              {{ sound.size ? "MB" : "" }}
            </div>

            <audio
              :src="`${sound.src}`"
              v-if="sound.src"
              :ref="`player-${index}`"
              type="audio/mpeg"
            ></audio>

            <div class="d-flex align-center">
              <v-btn
                icon
                small
                color="primary"
                @click="playSound(index)"
                v-if="!sound.sound"
                class="mr-2"
              >
                <v-icon>{{
                  soundPlaying == index ? "mdi-pause" : "mdi-play"
                }}</v-icon>
              </v-btn>

              <v-btn icon small color="error" @click="onDeleteSound(sound)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>

          <div class="new-file-badge pa-1 success" v-if="isEdit && sound.sound">
            <div class="caption white--text text-center">Νέο</div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="deleteSoundDialog.open" max-width="500">
      <div
        class="white d-flex flex-column align-center pa-4"
        v-if="deleteSoundDialog.open"
      >
        <div class="body-1 font-weight-medium text-center mb-4">
          Είστε σίγουροι ότι θέλετε να διαγράψετε αυτόν τον ήχο;
        </div>

        <div
          class="body-2 text-center py-1 grey lighten-4 mb-4"
          v-if="deleteSoundDialog.soundToDelete.sound"
        >
          {{ deleteSoundDialog.soundToDelete.sound.name }}
        </div>

        <div class="d-flex justify-center">
          <v-btn
            color="error"
            small
            class="mr-2"
            @click="deleteSound(deleteSoundDialog.soundToDelete)"
            >Διαγραφη</v-btn
          >

          <v-btn
            color="primary"
            small
            @click="
              deleteSoundDialog = {
                open: false,
                soundToDelete: null,
              }
            "
            >Ακυρωση</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["soundsErrors", "existingSoundsPreviews", "isEdit"],
  data() {
    return {
      sounds: [],
      allSounds: [],
      soundsToDelete: [],
      deleteSoundDialog: {
        open: false,
        soundToDelete: null,
      },
      soundPlaying: null,
    };
  },
  computed: {
    soundPreviews() {
      return [
        ...this.allSounds.map((sound) => {
          const size = sound.size / 1024 / 1024;
          const roundedSize = Math.round((size + Number.EPSILON) * 100) / 100;

          return {
            sound,
            size: roundedSize,
          };
        }),
        ...this.existingSoundsPreviews,
      ];
    },
  },
  methods: {
    onChange() {
      this.sounds.forEach((sound) => {
        const found = this.allSounds.find((s) => s.name == sound.name);

        if (!found) this.allSounds.push(sound);
      });

      this.$emit("allSoundsChanged", this.allSounds);

      this.sounds = [];
    },
    onDeleteSound(sound) {
      this.deleteSoundDialog = {
        open: true,
        soundToDelete: sound,
      };
    },
    deleteSound(soundObject) {
      if (soundObject.id) {
        this.soundsToDelete.push({
          id: soundObject.id,
          path: soundObject.path,
          thumb: soundObject.thumb,
        });

        this.$emit("soundsToDeleteChanged", this.soundsToDelete);

        this.$nextTick();
        this.soundPlaying = null;
      } else {
        this.$delete(this.allSounds, this.allSounds.indexOf(soundObject.sound));

        if (this.allSounds.length == 0) this.$refs.sounds.reset();
      }

      this.deleteSoundDialog = {
        open: false,
        soundToDelete: null,
      };
    },
    playSound(index) {
      if (this.soundPlaying == index) {
        this.$refs[`player-${index}`][0].pause();
        this.$refs[`player-${index}`][0].currentTime = 0;
        this.soundPlaying = null;
        return;
      }

      if (this.soundPlaying != null) {
        this.$refs[`player-${this.soundPlaying}`][0].pause();
        this.$refs[`player-${index}`][0].currentTime = 0;
        this.soundPlaying = null;
      }

      this.$refs[`player-${index}`][0].play();
      this.soundPlaying = index;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>