<template>
  <div class="component-wrapper d-flex flex-column">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["resetProgramState"])
  },
  destroyed() {
    this.resetProgramState();
  },
};
</script>

<style lang="scss" scoped>
</style>