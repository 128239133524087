<template>
  <div class="d-flex flex-column rounded pb-4 card elevation-3">
    <div class="image-wrapper d-flex flex-column justify-center rounded-t mb-4">
      <v-icon x-large color="black"> mdi-eye-off </v-icon>
      <div class="body-1 text-center">Δεν υπάρχει διαθέσιμη εικόνα</div>
    </div>

    <v-divider class="mb-2"></v-divider>

    <div class="subtitle-1 font-weight-medium text-center mb-2">
      {{ museum.museumLocales[0].title }}
    </div>

    <v-btn
      class="align-self-center"
      color="warning lighten-1"
      small
      @click="$router.push({ path: `/superuser/museum/${museum.id}` })"
    >
      <v-icon left>mdi-pencil</v-icon>
      Επεξεργασια
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["museum"],
};
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid #e0e0e0;
  background-color: #fafafa;
}

.image-wrapper {
  height: 200px;
}
</style>