<template>
  <div class="component-wrapper d-flex">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      triggersetListeners: (state) => state.twilio.triggersetListeners,
      roomContent: (state) => state.shadow.roomContent,
    }),
  },
  methods: {
    ...mapActions(["setListeners", "disconnect"]),
    ...mapMutations(["setAllowLeave"]),
  },
  watch: {
    triggersetListeners(newValue) {
      if (newValue) {
        this.setListeners();
      }
    },
    roomContent: {
      immediate: true,
      handler(newValue, oldValue) {
        const hasContentTypeChanges =
          newValue?.openedContentType &&
          (!oldValue?.openedContentType ||
            newValue?.openedContentType !== oldValue?.openedContentType);

        if (hasContentTypeChanges) {
          this.setAllowLeave(true);

          if (newValue.openedContentType == "image") {
            this.$router.push("/room/content/image");
          } else if (newValue.openedContentType == "video") {
            this.$router.push("/room/content/video");
          } else {
            this.$router.push("/room/camera");
          }
        }

        if (!newValue?.openedContentType && oldValue?.openedContentType) {
          this.setAllowLeave(true);
          this.$router.push("/room/camera");
        }
      },
    },
  },
  destroyed() {
    this.disconnect();
  },
};
</script>

<style lang="scss" scoped>
</style>