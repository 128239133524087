import axios from 'axios'

export default {
    state: {
        token: localStorage.getItem('token') || null,
        role: localStorage.getItem('role') || null
    },
    getters: {
        isLoggedIn: state => !!state.token
    },
    mutations: {
        login(state, user) {
            state.token = user.token;
            state.role = user.role;
        },
        logout(state) {
            state.token = null;
            state.role = null;
        }
    },
    actions: {
        async login({ commit, dispatch }, credentials) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/auth/login`, {
                    email: credentials.email,
                    password: credentials.password,
                })

                const token = res.data.token;
                const role = res.data.role;

                localStorage.setItem('token', token);
                localStorage.setItem('role', role);

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

                commit('login', {
                    token,
                    role
                });

                return role;
            } catch (e) {
                dispatch("logout");
                throw e;
            }
        },
        async logout({ commit }) {
            commit('logout');
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            delete axios.defaults.headers.common['Authorization'];
        }
    },
}
