<template>
  <v-app-bar app elevation="3">
    <img
      src="@/assets/logo.svg"
      class="logo mr-auto"
      :class="{ 'in-room': inRoom }"
      @click="
        !inRoom &&
          $router.push({ path: `${role == 'superuser' ? '/superuser' : '/'}` })
      "
    />

    <v-btn
      small
      color="primary"
      class="mr-2"
      @click="$router.push({ path: '/shadow' })"
      v-if="!inRoom && isLoggedIn && role == 'superuser'"
    >
      <v-icon left small>mdi-eye</v-icon>
      Παρακολουθηση παρουσιασης
    </v-btn>

    <v-btn small depressed color="primary" v-if="isLoggedIn" @click="onLogout"
      >Αποσυνδεση
      <v-icon right small> mdi-logout </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState({
      role: (state) => state.auth.role,
    }),
    inRoom() {
      return this.$route.path.includes("/room/");
    },
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapMutations(["setAllowLeave"]),
    async onLogout() {
      await this.logout();

      if (this.inRoom) {
        this.setAllowLeave(true);
      }

      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 28px;

  &:not(.in-room) {
    cursor: pointer;
  }
}
</style>
