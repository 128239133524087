<template>
  <div>
    <v-file-input
      outlined
      dense
      prepend-inner-icon="mdi-camera"
      prepend-icon=""
      multiple
      accept="image/png, image/jpeg, image/jpg"
      :clearable="false"
      v-model="photos"
      @change="onChange"
      :placeholder="`${photosPreviews.length} Εικόν${
        photosPreviews.length == 1 ? 'α' : 'ες'
      }`"
      hint="Αρχεία JPG/PNG μέχρι και 2.5MB ανά αρχείο"
      :error-messages="photosErrors"
      persistent-hint
      ref="photos"
      class="mb-1"
      id="photoInput"
    >
    </v-file-input>

    <v-row class="mb-5">
      <v-col cols="3">
        <div class="file-wrapper rounded">
          <label
            class="
              d-flex
              flex-column
              align-center
              justify-center
              add-file
              grey
              lighten-4
              pa-2
            "
            for="photoInput"
          >
            <v-icon color="primary" class="mb-2">mdi-plus</v-icon>
            <div class="body-2 primary--text text-center">
              Προσθήκη Φωτογραφίας
            </div>
          </label>
        </div>
      </v-col>

      <v-col cols="3" v-for="(photo, index) in photosPreviews" :key="index">
        <div class="file-wrapper rounded">
          <div
            class="
              file-overlay
              d-flex
              flex-column
              align-center
              justify-center
              rounded
            "
          >
            <div class="body-2 font-weight-bold white--text">
              {{ photo.size ? photo.size : "" }}
              {{ photo.size ? "MB" : "" }}
            </div>

            <v-btn icon small color="error" @click="onDeletePhoto(photo)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>

          <img :src="photo.id ? photo.src : photo.preview" class="rounded" />

          <div
            class="new-file-badge pa-1 success"
            v-if="isEdit && photo.preview"
          >
            <div class="caption white--text text-center">Νέο</div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="deletePhotoDialog.open" max-width="500">
      <div
        class="white d-flex flex-column align-center pa-4"
        v-if="deletePhotoDialog.open"
      >
        <div class="body-1 font-weight-medium text-center mb-4">
          Είστε σίγουροι ότι θέλετε να διαγράψετε αυτήν τη φωτογραφία;
        </div>

        <img
          :src="
            deletePhotoDialog.photoToDelete.id
              ? deletePhotoDialog.photoToDelete.src
              : deletePhotoDialog.photoToDelete.preview
          "
          class="file-to-delete rounded mb-4"
        />

        <div class="d-flex justify-center">
          <v-btn
            color="error"
            small
            class="mr-2"
            @click="deletePhoto(deletePhotoDialog.photoToDelete)"
            >Διαγραφη</v-btn
          >

          <v-btn
            color="primary"
            small
            @click="
              deletePhotoDialog = {
                open: false,
                photoToDelete: null,
              }
            "
            >Ακυρωση</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["photosErrors", "existingPhotosPreviews", "isEdit"],
  data() {
    return {
      photos: [],
      allPhotos: [],
      photosToDelete: [],
      deletePhotoDialog: {
        open: false,
        photoToDelete: null,
      },
    };
  },
  computed: {
    photosPreviews() {
      return [
        ...this.allPhotos.map((photo) => {
          const size = photo.size / 1024 / 1024;
          const roundedSize = Math.round((size + Number.EPSILON) * 100) / 100;

          return {
            photo,
            size: roundedSize,
            preview: URL.createObjectURL(photo),
          };
        }),
        ...this.existingPhotosPreviews,
      ];
    },
  },
  methods: {
    onChange() {
      this.photos.forEach((photo) => {
        const found = this.allPhotos.find((p) => p.name == photo.name);

        if (!found) this.allPhotos.push(photo);
      });

      this.$emit("allPhotosChanged", this.allPhotos);

      this.photos = [];
    },
    onDeletePhoto(photo) {
      this.deletePhotoDialog = {
        open: true,
        photoToDelete: photo,
      };
    },
    deletePhoto(photoObject) {
      if (photoObject.id) {
        this.photosToDelete.push({
          id: photoObject.id,
          path: photoObject.path,
          thumb: photoObject.thumb,
        });

        this.$emit("photosToDeleteChanged", this.photosToDelete);
      } else {
        this.$delete(this.allPhotos, this.allPhotos.indexOf(photoObject.photo));

        if (this.allPhotos.length == 0) this.$refs.photos.reset();
      }

      this.deletePhotoDialog = {
        open: false,
        photoToDelete: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>