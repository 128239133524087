<template>
  <div class="component-wrapper inner d-flex flex-column align-center">
    <v-btn
      icon
      color="primary"
      @click="
        $router.push({
          path: `/superuser/museum/${activeMuseum.id}/programs`,
        })
      "
      class="close-button"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="body-2 text-center mb-5">
      ({{ activeMuseum.museumLocales[0].title }})
    </div>

    <form class="inner-wrapper d-flex flex-column" @submit.prevent="submit">
      <Step1Info class="mb-12" />

      <v-checkbox
        v-model="testingMode"
        label="Ενεργοποιημένη περίοδος δοκικής"
        class="ma-0 pa-0 mb-4"
        hide-details
        :ripple="false"
      ></v-checkbox>

      <div class="body-2 mb-1 error--text text--lighten-1" v-if="testingMode">
        Απομένουν:
        {{
          testProgramHours != 1
            ? `${testProgramHours} ώρες`
            : `${testProgramHours} ώρα`
        }}
        και
        {{
          testProgramMinutes != 1
            ? `${testProgramMinutes} λεπτά`
            : `${testProgramMinutes} λεπτό`
        }}
      </div>

      <div class="body-1" v-if="testingMode">Αύξηση περιόδου δοκιμής κατά:</div>

      <div class="d-flex mt-4" v-if="testingMode">
        <v-select
          label="Ώρες"
          outlined
          dense
          class="mr-2"
          :items="hours"
          v-model="selectedHours"
        ></v-select>

        <v-select
          label="Λεπτά"
          outlined
          dense
          :items="minutes"
          v-model="selectedMinutes"
        ></v-select>
      </div>

      <v-btn
        depressed
        small
        color="primary"
        class="align-self-center"
        type="submit"
        :disabled="!hasChanges"
        :loading="loader"
        >Αποθήκευση</v-btn
      >
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Step1Info from "@/views/program/step1/Step1Info";
import moment from "moment";

export default {
  components: {
    Step1Info,
  },
  data() {
    return {
      hours: ["0", "1", "2", "3", "4"],
      minutes: [],
      testingMode: false,
      selectedHours: "0",
      selectedMinutes: "00",
      testProgramHours: 0,
      testProgramMinutes: 0,
      initialData: null,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      currentProgram: (state) => state.admin.currentProgram,
      activeMuseum: (state) => state.superuser.activeMuseum,
    }),
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !==
        JSON.stringify({
          testingMode: this.testingMode,
          selectedHours: this.testingMode ? this.selectedHours : "0",
          selectedMinutes: this.testingMode ? this.selectedMinutes : "00",
        })
      );
    },
  },
  created() {
    this.minutes = Array.from(Array(60).keys()).map((m) =>
      m.toString().padStart(2, "0")
    );

    this.init();
  },
  methods: {
    ...mapActions(["editProgram"]),
    init() {
      if (this.currentProgram) {
        this.testingMode = this.currentProgram.testingMode;

        if (
          this.testingMode &&
          this.currentProgram.remainingTestingTimeInSeconds
        ) {
          this.testProgramHours = moment()
            .startOf("day")
            .seconds(this.currentProgram.remainingTestingTimeInSeconds)
            .format("H");

          this.testProgramMinutes = moment()
            .startOf("day")
            .seconds(this.currentProgram.remainingTestingTimeInSeconds)
            .format("m");
        } else {
          this.testProgramMinutes = 0;
          this.testProgramHours = 0;
        }
      }

      this.selectedHours = "0";
      this.selectedMinutes = "00";

      this.initialData = {
        testingMode: this.testingMode,
        selectedHours: this.selectedHours,
        selectedMinutes: this.selectedMinutes,
      };
    },
    getTotalSeconds() {
      const secondsFromHours = parseInt(this.selectedHours) * 60 * 60;
      const secondsFromMinutes = parseInt(this.selectedMinutes) * 60;
      const totalSeconds = secondsFromHours + secondsFromMinutes;

      return totalSeconds;
    },
    async submit() {
      this.loader = true;

      try {
        await this.editProgram({
          programId: this.currentProgram.id,
          testingMode: this.testingMode,
          remainingTestingTimeInSeconds: this.getTotalSeconds(),
        });

        this.init();
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
