<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <Header class="mb-5" />

    <div class="d-flex flex-grow-1 align-self-stretch">
      <div class="left d-flex flex-column justify-space-around mr-5">
        <div
          class="content-video elevation-5 rounded d-flex flex-column"
          ref="guide"
        >
          <div
            class="
              text-center
              pa-1
              body-1
              font-weight-medium
              primary--text
              camera-header
            "
          >
            ΞΕΝΑΓΟΣ
          </div>

          <div
            class="track-msg white--text body-1 text-center"
            v-if="guideConnected && !guideVideoState"
          >
            Η κάμερά του ξεναγού είναι απενεργοποιημένη
          </div>

          <div class="track-msg body-1 text-center" v-if="!guideConnected">
            Ο ξεναγός δεν έχει συνδεθεί
          </div>
        </div>

        <div
          class="content-video elevation-5 rounded d-flex flex-column"
          ref="school"
        >
          <div
            class="
              text-center
              pa-1
              body-1
              font-weight-medium
              primary--text
              camera-header
            "
          >
            ΣΧΟΛΕΙΟ
          </div>

          <div
            class="track-msg white--text body-1 text-center"
            v-if="schoolConnected && !schoolVideoState"
          >
            Η κάμερά του σχολείου είναι απενεργοποιημένη
          </div>

          <div class="track-msg body-1 text-center" v-if="!schoolConnected">
            Το σχολείο δεν έχει συνδεθεί
          </div>
        </div>
      </div>

      <div class="right d-flex">
        <router-view></router-view>
      </div>
    </div>

    <Buttons class="mt-5" />
  </div>
</template>

<script>
import Header from "@/views/shadow/Header";
import Buttons from "@/views/shadow/Buttons";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  components: {
    Header,
    Buttons,
  },
  computed: {
    ...mapState({
      triggersetListeners: (state) => state.twilio.triggersetListeners,
      guideConnected: (state) => state.twilio.guideConnected,
      schoolConnected: (state) => state.twilio.schoolConnected,
      guideVideoState: (state) => state.twilio.guideVideoState,
      schoolVideoState: (state) => state.twilio.schoolVideoState,
    }),
  },
  mounted() {
    this.changeRefs({
      guide: this.$refs.guide,
      school: this.$refs.school,
    });

    if (!this.triggersetListeners) this.setTriggersetListeners();
  },
  methods: {
    ...mapActions(["changeRefs", "detachTracks"]),
    ...mapMutations(["setTriggersetListeners"]),
  },
  destroyed() {
    this.detachTracks();
  },
};
</script>

<style lang="scss" scoped>
.left {
  width: 20%;
}

.right {
  flex: 1;
}

.camera-header {
  border-bottom: 1px solid #eee;
}
</style>