<template>
  <div class="component-wrapper inner d-flex flex-column align-center">
    <v-btn icon color="primary" @click="onClose" class="close-button">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="body-2 text-center mb-2" v-if="role == 'superuser'">
      ({{ activeMuseum.museumLocales[0].title }})
    </div>

    <div class="text-h6 primary--text text-center mb-1">Βήμα 2</div>

    <div class="text-h6 text-center title-underline mb-12">
      Υποστηρικτικό υλικό
    </div>

    <div class="inner-wrapper">
      <Step1Info class="mb-8" />

      <div class="d-flex align-center mb-8">
        <div class="subtitle-1 font-weight-medium">Ενότητες</div>

        <v-btn
          small
          color="primary"
          class="ml-4"
          @click="showSection = true"
          :disabled="showSection"
        >
          <v-icon left>mdi-plus</v-icon>
          Νεα ενοτητα</v-btn
        >
      </div>

      <div
        class="body-1"
        v-if="!currentProgram.sections.length && !showSection"
      >
        Δεν υπάρχουν διαθέσιμες ενότητες
      </div>

      <v-row
        v-for="section in currentProgram.sections"
        :key="section.id"
        class="d-flex align-center"
      >
        <v-col cols="3" class="body-1"
          >{{ section.order }}. {{ section.title }}</v-col
        >

        <v-col cols="9">
          <v-btn
            color="warning"
            small
            class="mr-2"
            @click="editSection(section)"
            width="134px"
            :disabled="showSection"
          >
            <v-icon left>mdi-pencil</v-icon>
            επεξεργασια
          </v-btn>

          <v-btn
            color="error"
            small
            @click="openDeleteDialog(section)"
            width="134px"
            :disabled="showSection"
          >
            <v-icon left>mdi-delete</v-icon>
            Διαγραφη
          </v-btn>
        </v-col>
      </v-row>

      <Section
        class="mt-10"
        v-if="showSection"
        :isEdit="!!selectedSection"
        :section="selectedSection"
        @closeSection="showSection = false"
      />

      <v-divider class="my-10"></v-divider>

      <div class="d-flex">
        <v-btn color="primary" small @click="onPrev" :disabled="showSection">
          <v-icon left>mdi-chevron-left</v-icon>

          Προηγουμενο
        </v-btn>

        <v-btn
          class="ml-auto"
          color="primary"
          small
          :disabled="!currentProgram.sections.length || showSection"
          @click="onNext"
        >
          Επομενο
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="deleteDialog" max-width="500px">
      <div class="white pa-2" v-if="deleteDialog">
        <template v-if="currentProgram.sections.length > 1">
          <div class="subtitle-1 text-center mb-2">
            Είστε βέβαιοι ότι θέλετε να διαγράψετε οριστικά την ενότητα
            {{ sectionToDelete.title }} ?
          </div>

          <div class="d-flex justify-center">
            <v-btn
              class="mr-2"
              small
              color="error"
              :loading="deleteLoader"
              @click="onDeleteSection"
              >Διαγραφη</v-btn
            >

            <v-btn small @click="closeDeleteDialog">Ακυρωση</v-btn>
          </div>
        </template>

        <template v-else>
          <div class="d-flex flex-column align-center">
            <v-icon class="primary rounded-circle pa-1 mb-2" color="white">
              mdi-exclamation
            </v-icon>

            <div class="subtitle-1 text-center mb-2">
              Το πρόγραμμα πρέπει να έχει τουλάχιστον μία ενότητα.
            </div>

            <v-btn color="primary" small @click="deleteDialog = false"
              >Ενταξει</v-btn
            >
          </div>
        </template>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Step1Info from "@/views/program/step1/Step1Info";
import Section from "@/views/program/step2/Section";

export default {
  components: {
    Step1Info,
    Section,
  },
  data() {
    return {
      showSection: false,
      deleteDialog: false,
      deleteLoader: false,
      sectionToDelete: null,
      selectedSection: null,
    };
  },
  computed: {
    ...mapState({
      currentProgram: (state) => state.admin.currentProgram,
      role: (state) => state.auth.role,
      activeMuseum: (state) => state.superuser.activeMuseum,
    }),
    isEdit() {
      return this.$route.path.includes("edit");
    },
  },
  methods: {
    ...mapActions(["deleteSection"]),
    ...mapMutations(["setAllowStep3"]),
    editSection(section) {
      this.selectedSection = section;
      this.showSection = true;
    },
    openDeleteDialog(section) {
      this.deleteDialog = true;
      this.sectionToDelete = section;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.sectionToDelete = null;
    },
    async onDeleteSection() {
      this.deleteLoader = true;

      try {
        await this.deleteSection(this.sectionToDelete);
        this.deleteLoader = false;
        this.closeDeleteDialog();
      } catch (e) {
        console.log(e);
        this.deleteLoader = false;
        this.closeDeleteDialog();
      }
    },
    onClose() {
      if (this.role == "admin") {
        this.$router.push({ path: "/" });
      } else {
        this.$router.push({
          path: `/superuser/museum/${this.activeMuseum.id}/programs`,
        });
      }
    },
    onPrev() {
      let path;

      if (this.isEdit) {
        if (this.role == "admin") {
          path = `/program/edit/${this.currentProgram.uniqueCode}/step1`;
        } else {
          path = `/superuser/museum/${this.activeMuseum.id}/program/edit/${this.currentProgram.uniqueCode}/step1`;
        }
      } else {
        if (this.role == "admin") {
          path = `/program/new/step1`;
        } else {
          path = `/superuser/museum/${this.activeMuseum.id}/program/new/step1`;
        }
      }

      this.$router.push({
        path,
      });
    },
    onNext() {
      let path;

      if (this.isEdit) {
        if (this.role == "admin") {
          path = `/program/edit/${this.currentProgram.uniqueCode}/step3`;
        } else {
          path = `/superuser/museum/${this.activeMuseum.id}/program/edit/${this.currentProgram.uniqueCode}/step3`;
        }
      } else {
        this.setAllowStep3(true);
        if (this.role == "admin") {
          path = `/program/new/step3`;
        } else {
          path = `/superuser/museum/${this.activeMuseum.id}/program/new/step3`;
        }
      }

      this.$router.push({
        path,
      });
    },
  },
  watch: {
    showSection(newValue) {
      if (newValue) {
        this.$vuetify.goTo(999);
      } else {
        this.$vuetify.goTo(0);
        this.selectedSection = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>