import store from '@/store'

import SuperUser from '@/views/superUser/SuperUser.vue'
import NewMuseum from '@/views/superUser/NewMuseum.vue'
import EditMuseum from '@/views/superUser/EditMuseum.vue'
import EditMuseumLanding from '@/views/superUser/EditMuseumLanding.vue'
import EditMuseumUsers from '@/views/superUser/EditMuseumUsers.vue'

import Home from '@/views/home/Home.vue'
import Program from '@/views/program/Program'
import Step1 from '@/views/program/step1/Step1'
import Step2 from '@/views/program/step2/Step2'
import Step3 from '@/views/program/step3/Step3'
import Trial from '@/views/program/Trial'

const isSuperUser = (to, from, next) => {
    if (!store.getters.isLoggedIn) {
        next('/login')
        return;
    }

    if (store.state.auth.role == 'admin') {
        next('/');
        return;
    }

    next()
}

export default [
    // ====== HOME PAGE ======
    {
        path: '/superuser',
        component: SuperUser,
        beforeEnter: isSuperUser,
    },
    // ====== NEW MUSEUM ======
    {
        path: '/superuser/new-museum',
        component: NewMuseum,
        beforeEnter: isSuperUser,
    },
    // ====== EDIT MUSEUM ======
    {
        path: '/superuser/museum/:id',
        component: EditMuseum,
        async beforeEnter(to, from, next) {

            if (!store.getters.isLoggedIn) {
                next('/login')
                return;
            }

            if (store.state.auth.role == 'admin') {
                next('/');
                return;
            }

            await store.dispatch("getMuseums");

            store.commit("setActiveMuseum", to.params.id);

            if (!store.state.superuser.activeMuseum) {
                next('/superuser');
            }

            next();
        },
        children: [
            // ====== EDIT MUSEUM LANDING ======
            {
                path: '/',
                component: EditMuseumLanding,
            },
            // ====== EDIT MUSEUM USERS ======
            {
                path: 'users',
                component: EditMuseumUsers,
            },
            // ====== EDIT MUSEUM USERS ======
            {
                path: 'programs',
                component: Home,
            },
            // ====== NEW PROGRAM ======
            {
                path: 'program',
                component: Program,
                children: [
                    // ====== STEP 1 ======
                    {
                        path: 'new/step1',
                        component: Step1
                    },
                    // ====== STEP 2 ======
                    {
                        path: 'new/step2',
                        component: Step2,
                        beforeEnter: (to, from, next) => {
                            if (store.state.admin.allowStep2) {
                                next();
                            } else {
                                next('/');
                            }
                        }
                    },
                    // ====== STEP 3 ======
                    {
                        path: 'new/step3',
                        component: Step3,
                        beforeEnter: (to, from, next) => {
                            if (store.state.admin.allowStep3) {
                                next();
                            } else {
                                next('/');
                            }
                        }
                    },
                    // ====== EDIT PROGRAM ======
                    // ====== TRIAL ======
                    {
                        path: 'edit/:programId/trial',
                        component: Trial,
                        beforeEnter: (to, from, next) => {
                            store.commit("setCurrentProgram", to.params?.programId);

                            if (store.state.admin.currentProgram) {
                                next();
                            } else {
                                next('/');
                            }
                        }
                    },
                    // ====== STEP 1 (EDIT) ======
                    {
                        path: 'edit/:programId/step1',
                        component: Step1,
                        beforeEnter: (to, from, next) => {
                            store.commit("setCurrentProgram", to.params?.programId);

                            if (store.state.admin.currentProgram) {
                                next();
                            } else {
                                next('/');
                            }
                        }
                    },
                    // ====== STEP 2 (EDIT) ======
                    {
                        path: 'edit/:programId/step2',
                        component: Step2,
                        beforeEnter: (to, from, next) => {
                            store.commit("setCurrentProgram", to.params?.programId);

                            if (store.state.admin.currentProgram) {
                                next();
                            } else {
                                next('/');
                            }
                        }
                    },
                    // ====== STEP 3 (EDIT) ======
                    {
                        path: 'edit/:programId/step3',
                        component: Step3,
                        beforeEnter: (to, from, next) => {
                            store.commit("setCurrentProgram", to.params?.programId);

                            if (store.state.admin.currentProgram) {
                                next();
                            } else {
                                next('/');
                            }
                        }
                    },
                ]
            },
        ]
    },
]
