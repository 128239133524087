<template>
  <v-dialog :value="open" persistent width="300">
    <v-card color="primary" dark class="pa-1">
      <v-card-text>
        <div class="body-1 text-center mb-2">{{ text }}</div>

        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["text", "open"],
};
</script>