<template>
  <div class="component-wrapper inner d-flex flex-column align-center">
    <v-btn icon color="primary" @click="onBack" class="close-button">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="body-2 text-center mb-2" v-if="role == 'superuser'">
      ({{ activeMuseum.museumLocales[0].title }})
    </div>

    <div class="text-h6 primary--text text-center mb-1">Βήμα 1</div>

    <div class="text-h6 text-center title-underline mb-12">
      Στοιχεία Προγράμματος
    </div>

    <div class="inner-wrapper d-flex flex-column">
      <v-text-field
        outlined
        label="Τίτλος"
        v-model="title"
        :error-messages="titleErrors"
        class="mb-2"
      ></v-text-field>

      <v-text-field
        outlined
        label="Μοναδικός Κωδικός"
        v-model="uniqueCode"
        :error-messages="uniqueCodeErrors"
        :disabled="role == 'admin'"
        class="mb-2"
      ></v-text-field>

      <v-btn
        color="primary"
        class="align-self-center"
        :loading="saveLoader"
        @click="newOrEditProgram()"
        v-if="isEdit"
        :disabled="!hasChanges"
      >
        <v-icon left>mdi-content-save</v-icon>
        Αποθηκευση
      </v-btn>

      <v-divider class="my-10"></v-divider>

      <div class="d-flex">
        <v-btn color="primary" small disabled>
          <v-icon left>mdi-chevron-left</v-icon>

          Προηγουμενο
        </v-btn>

        <v-btn
          class="ml-auto"
          color="primary"
          small
          @click="newOrEditProgram(isEdit)"
          :loading="loader"
          :disabled="hasChanges && isEdit"
        >
          Επομενο
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="confirmDialog" max-width="450">
      <div class="white pa-3">
        <div class="body-1 text-center mb-4">
          Έχετε μη αποθηκευμένες αλλαγές. Θέλετε να σταματήσετε την διαδικασία
          χωρίς αποθήκευση?
        </div>

        <div class="d-flex justify-center">
          <v-btn
            color="error"
            small
            class="mr-2"
            @click="
              forceLeave = true;
              $router.push({ path: pathToLeave });
            "
            >Συνεχεια</v-btn
          >

          <v-btn color="primary" small @click="confirmDialog = false"
            >Παραμονη</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapMutations, mapState } from "vuex";

const uniqueTitleValidator = function () {
  return !this.titleExists;
};

const uniqueCodeValidator = function () {
  return !this.uniqueCodeExists;
};

export default {
  data() {
    return {
      title: "",
      uniqueCode: "",
      titleExists: false,
      uniqueCodeExists: false,
      loader: false,
      saveLoader: false,
      initialData: null,
      confirmDialog: false,
      forceLeave: false,
      pathToLeave: null,
    };
  },
  validations: {
    title: { required, uniqueTitleValidator },
    uniqueCode: { required, uniqueCodeValidator },
  },
  computed: {
    ...mapState({
      currentProgram: (state) => state.admin.currentProgram,
      allowStep2: (state) => state.admin.allowStep2,
      role: (state) => state.auth.role,
      activeMuseum: (state) => state.superuser.activeMuseum,
    }),
    isEdit() {
      return this.$route.path.includes("edit");
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      if (!this.$v.title.required) errors.push("Ο τίτλος είναι απαραίτητος");
      if (!this.$v.title.uniqueTitleValidator)
        errors.push("Αυτός ο τίτλος χρησιμοποείται ήδη.");
      return errors;
    },
    uniqueCodeErrors() {
      const errors = [];
      if (!this.$v.uniqueCode.$dirty) return errors;
      if (!this.$v.uniqueCode.required)
        errors.push("Ο μοναδικός κωδικός είναι απαραίτητος");
      if (!this.$v.uniqueCode.uniqueCodeValidator)
        errors.push("Αυτός ο μοναδικός κωδικός χρησιμοποείται ήδη.");
      return errors;
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !==
        JSON.stringify({
          title: this.title,
          uniqueCode: this.uniqueCode,
        })
      );
    },
  },
  created() {
    this.title = this.currentProgram?.programLocales[0]?.title;
    this.uniqueCode = this.currentProgram?.uniqueCode;

    this.initialData = {
      title: this.title,
      uniqueCode: this.uniqueCode,
    };
  },
  methods: {
    ...mapActions(["newProgram", "editProgram"]),
    async newOrEditProgram(next = false) {
      this.titleExists = false;
      this.uniqueCodeExists = false;

      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (!this.hasChanges) {
        if (this.isEdit) {
          if (this.role == "admin") {
            this.$router.push({
              path: `/program/edit/${this.currentProgram.uniqueCode}/step2`,
            });
          } else {
            this.$router.push({
              path: `/superuser/museum/${this.activeMuseum.id}/program/edit/${this.currentProgram.uniqueCode}/step2`,
            });
          }
        } else {
          if (this.role == "admin") {
            this.$router.push({ path: "/program/new/step2" });
          } else {
            this.$router.push({
              path: `/superuser/museum/${this.activeMuseum.id}/program/new/step2`,
            });
          }
        }

        return;
      }

      this.isEdit ? (this.saveLoader = true) : (this.loader = true);

      const formData = new FormData();

      if (!this.isEdit) {
        formData.set("title", this.title);
        formData.set("uniqueCode", this.uniqueCode);
      }

      try {
        if (!this.isEdit && !this.allowStep2) {
          await this.newProgram(formData);
          this.loader = false;

          this.title = this.currentProgram.programLocales[0].title;
          this.uniqueCode = this.currentProgram.uniqueCode;

          this.initialData = {
            title: this.title,
            uniqueCode: this.uniqueCode,
          };

          if (this.role == "admin") {
            this.$router.push({ path: "/program/new/step2" });
          } else {
            this.$router.push({
              path: `/superuser/museum/${this.activeMuseum.id}/program/new/step2`,
            });
          }
        } else {
          await this.editProgram({
            programId: this.currentProgram.id,
            uniqueCode: this.uniqueCode,
            title: this.title,
          });

          this.title = this.currentProgram.programLocales[0].title;
          this.uniqueCode = this.currentProgram.uniqueCode;

          this.initialData = {
            title: this.title,
            uniqueCode: this.uniqueCode,
          };

          this.saveLoader = false;

          if (!this.isEdit) {
            this.loader = false;

            if (this.role == "admin") {
              this.$router.push({ path: "/program/new/step2" });
            } else {
              this.$router.push({
                path: `/superuser/museum/${this.activeMuseum.id}/program/new/step2`,
              });
            }
          }
        }
      } catch (e) {
        if (e.response.status == 400) {
          if (e.response?.data?.data?.uniqueCode) {
            this.uniqueCodeExists = true;
          }

          if (e.response?.data?.data?.title) {
            this.titleExists = true;
          }
        }
        this.loader = false;
        this.saveLoader = false;
      }
    },
    onBack() {
      if (this.role == "admin") {
        this.$router.push({ path: "/" });
      } else {
        this.$router.push({
          path: `/superuser/museum/${this.activeMuseum.id}/programs`,
        });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges && !this.forceLeave) {
      this.pathToLeave = to.path;
      this.confirmDialog = true;
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
