<template>
  <div class="component-wrapper d-flex flex-column align-center pt-12">
    <div class="text-h6 mb-8">Σύνδεση Διαχειριστή</div>

    <form class="form d-flex flex-column" @submit.prevent="submit" novalidate>
      <v-text-field
        outlined
        type="email"
        label="Εmail"
        v-model="email"
        :error="wrongCredentials"
      ></v-text-field>

      <v-text-field
        outlined
        type="password"
        label="Κωδικός"
        v-model="password"
        :error="wrongCredentials"
      ></v-text-field>

      <div class="body-2 error--text mb-4" v-if="wrongCredentials">
        Λανθασμένα στοιχεία πρόσβασης. Προσπαθείστε ξανά.
      </div>

      <v-btn
        class="align-self-center"
        type="submit"
        :loading="loader"
        :disabled="!email || !password"
        color="primary"
        >Συνδεση</v-btn
      >
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      loader: false,
      wrongCredentials: false,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async submit() {
      this.wrongCredentials = false;
      this.loader = true;

      try {
        const role = await this.login({
          email: this.email,
          password: this.password,
        });
        this.loader = false;

        if (role == "superuser") {
          this.$router.push({ path: "/superuser" });
        } else {
          this.$router.push({ path: "/" });
        }
      } catch (e) {
        this.loader = false;

        if (e.response.status == 400) {
          this.wrongCredentials = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 400px;
}
</style>
