<template>
  <div
    class="component-wrapper d-flex flex-column"
    :class="{ 'pt-8': role == 'admin' }"
  >
    <div v-if="role == 'superuser'" class="mb-8">
      <v-btn
        icon
        color="primary"
        @click="$router.push({ path: `/superuser/museum/${activeMuseum.id}` })"
        class="close-button"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <div class="text-h6 text-center title-underline">
        {{ activeMuseum.museumLocales[0].title }}
      </div>
    </div>

    <v-btn
      class="align-self-start mb-12"
      small
      color="primary"
      @click="onNewProgram"
      v-if="role == 'superuser'"
    >
      <v-icon left small>mdi-plus</v-icon>
      Νεο Προγραμμα
    </v-btn>

    <v-progress-circular
      indeterminate
      color="primary"
      width="3"
      size="55"
      class="mx-auto mt-12"
      v-if="loader"
    ></v-progress-circular>

    <template v-else>
      <div class="body-1" v-if="!programs.length">
        Προσθέστε το πρώτο σας πρόγραμμα!
      </div>

      <v-row class="flex-grow-0">
        <v-col
          xl="3"
          lg="4"
          md="6"
          sm="6"
          cols="12"
          v-for="program in programs"
          :key="program.id"
          class="d-flex flex-column"
        >
          <ProgramCard :program="program" />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProgramCard from "@/views/home/ProgramCard";

export default {
  components: {
    ProgramCard,
  },
  data() {
    return {
      loader: false,
    };
  },
  computed: {
    ...mapState({
      programs: (state) => state.admin.programs,
      role: (state) => state.auth.role,
      activeMuseum: (state) => state.superuser.activeMuseum,
    }),
  },
  async created() {
    this.loader = true;
    await this.getPrograms();
    this.loader = false;
  },
  methods: {
    ...mapActions(["getPrograms"]),
    onNewProgram() {
      if (this.role == "admin") {
        this.$router.push({ path: "/program/new/step1" });
      } else {
        this.$router.push({
          path: `/superuser/museum/${this.activeMuseum.id}/program/new/step1`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
