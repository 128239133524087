<template>
  <div class="d-flex flex-column rounded pb-4 card elevation-3 flex-grow-1">
    <div
      class="
        program-badge
        success
        white--text
        d-flex
        align-center
        justify-center
        text-center
        body-2
        elevation-1
      "
      v-if="program.isDraft"
    >
      Draft
    </div>

    <div class="image-wrapper d-flex flex-column justify-center rounded-t mb-4">
      <v-icon x-large color="black"> mdi-eye-off </v-icon>
      <div class="body-1 text-center">Δεν υπάρχει διαθέσιμη εικόνα</div>
    </div>

    <v-divider class="mb-2"></v-divider>

    <div class="subtitle-1 font-weight-medium text-center">
      {{ program.programLocales[0].title }}
    </div>

    <div
      class="subtitle-2 error--text text--lighten-1 text-center"
      v-if="program.testingMode"
    >
      (Ενεργοποιημένη περίοδος δοκικής)
    </div>

    <div
      class="body-2 text-center mb-1 error--text text--lighten-1"
      v-if="program.testingMode"
    >
      Απομένουν:
      {{
        testProgramHours != 1
          ? `${testProgramHours} ώρες`
          : `${testProgramHours} ώρα`
      }}
      και
      {{
        testProgramMinutes != 1
          ? `${testProgramMinutes} λεπτά`
          : `${testProgramMinutes} λεπτό`
      }}
    </div>

    <div class="d-flex justify-center mt-auto pt-2">
      <v-btn
        color="warning lighten-1"
        small
        @click="onEditProgram(program.uniqueCode)"
      >
        <v-icon left>mdi-pencil</v-icon>
        Επεξεργασια
      </v-btn>

      <v-btn
        class="ml-2"
        small
        depressed
        color="error lighten-1"
        v-if="role == 'superuser'"
        @click="onEditProgram(program.uniqueCode, true)"
        >Δοκιμαστικο</v-btn
      >
    </div>

    <div class="d-flex justify-center mt-2">
      <v-btn
        color="error"
        class="ml-2"
        small
        @click="onOpenDeleteDialog(program)"
        v-if="program.isDraft"
      >
        <v-icon left>mdi-delete</v-icon>
        Διαγραφη
      </v-btn>

      <v-dialog v-model="dialog.open" max-width="500px">
        <div class="white pa-5" v-if="dialog.open">
          <div class="body-1 text-center mb-4">
            Είστε σίγουροι ότι θέλετε να διαγράψετε το προσχέδιο του
            προγράμματος <b>{{ dialog.program.programLocales[0].title }}</b
            >;
          </div>

          <div class="d-flex justify-center">
            <v-btn small class="mr-2" @click="dialog.open = false"
              >Ακυρωση</v-btn
            >

            <v-btn small color="error" @click="onDeleteDraft" :loading="loader"
              >Διαγραφη</v-btn
            >
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  props: ["program"],
  data() {
    return {
      dialog: {
        open: false,
        program: null,
      },
      loader: false,
      testProgramHours: 0,
      testProgramMinutes: 0,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.role,
      activeMuseum: (state) => state.superuser.activeMuseum,
    }),
  },
  created() {
    if (this.program.testingMode) {
      this.testProgramHours = moment()
        .startOf("day")
        .seconds(this.program.remainingTestingTimeInSeconds)
        .format("H");

      this.testProgramMinutes = moment()
        .startOf("day")
        .seconds(this.program.remainingTestingTimeInSeconds)
        .format("m");
    }
  },
  methods: {
    ...mapActions(["deleteDraft", "getPrograms"]),
    onOpenDeleteDialog(program) {
      this.dialog = {
        open: true,
        program,
      };
    },
    async onDeleteDraft() {
      this.loader = true;

      try {
        await this.deleteDraft(this.dialog.program.id);
        await this.getPrograms();
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
      this.dialog.open = false;
    },
    onEditProgram(uniqueCode, trial = false) {
      if (this.role == "admin") {
        this.$router.push({
          path: `/program/edit/${uniqueCode}/step1`,
        });
      } else {
        this.$router.push({
          path: `/superuser/museum/${
            this.activeMuseum.id
          }/program/edit/${uniqueCode}/${trial ? "trial" : "step1"}`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid #e0e0e0;
  background-color: #fafafa;
  position: relative;
}

.image-wrapper {
  height: 200px;
}

.program-badge {
  position: absolute;
  top: -15px;
  right: -15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
</style>
