import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Login from '@/views/Login'

import superuserRoutes from "@/router/superuser";
import adminRoutes from "@/router/admin";
import shadowRoutes from "@/router/shadow";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location)
    .catch(err => {
      if (err.name != "NavigationDuplicated") {
        throw err;
      }
    })
};

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next()
    return
  }

  next('/');
}

const routes = [
  // ====== LOGIN ======
  {
    path: '/login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  // ====== SUPER USER ======
  ...superuserRoutes,
  // ====== ADMIN ======
  ...adminRoutes,
  ...shadowRoutes,
  {
    path: "*",
    redirect: to => {
      if (store.getters.isLoggedIn) {
        if (store.state.auth.role == 'admin') {
          return '/'
        } else {
          return '/superuser'
        }
      } else {
        return '/login';
      }

    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
