<template>
  <v-app>
    <Navigation />

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar v-model="snackbar.open" :color="snackbar.color" timeout="4000">
      <div class="d-flex align-center">
        <div class="body-1 mr-2">{{ snackbar.text }}</div>

        <v-btn
          class="ml-auto"
          text
          small
          @click="toggleSnackbar({ open: false })"
          >OK</v-btn
        >
      </div>
    </v-snackbar>

    <Chat />
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import axios from "axios";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import Chat from "@/views/shadow/Chat";

export default {
  components: {
    Navigation,
    Chat,
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState({
      token: (state) => state.auth.token,
      snackbar: (state) => state.base.snackbar,
    }),
  },
  created() {
    //====== LOGIN STUFF ======
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          this.logout().then(() => {
            this.$router.push({ path: "/login" });
          });
        }
        throw error;
      }
    );
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapMutations(["toggleSnackbar"]),
  },
};
</script>

<style lang="scss" >
//====== VUETIFY OVERRIDES ======

.v-main__wrap {
  display: flex;
}

//====== GLOBAL STYLES ======
.component-wrapper {
  flex-grow: 1;
  padding: 20px;
  position: relative;

  &.inner {
    padding: 0;
    position: relative;

    .close-button {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .close-button {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
  }
}

.inner-wrapper {
  width: 600px;
  position: relative;
}

.title-underline {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 50%;
    width: 65px;
    height: 3px;
    background-color: var(--v-primary-base);
    transform: translateX(-50%);
  }
}

.reorder-checkbox {
  .v-input--selection-controls__input {
    background-color: white;
    height: 17px !important;
    width: 17px !important;
  }
}

.file-wrapper {
  padding-bottom: 100%;
  position: relative;

  .file-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(#000, 0.4);
    z-index: 1;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .add-file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
}

.file-to-delete {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

.new-file-badge {
  position: absolute;
  top: -8px;
  left: -8px;
  border-radius: 50%;
  z-index: 1;
}

.dotted {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.guide,
.school {
  width: 640px;
  height: 512px;
  position: relative;
  overflow: hidden;

  video {
    height: 480px;
    width: 640px;
    object-fit: contain;
  }
}

.content-video {
  overflow: hidden;
  height: 258px;
  position: relative;

  video {
    width: 100%;
    object-fit: cover;
    flex: 1;
  }
}

.track-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 3px 0 3px;
}
</style>
