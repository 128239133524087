var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper inner d-flex flex-column align-center"},[_c('v-btn',{staticClass:"close-button",attrs:{"icon":"","color":"primary"},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close")])],1),(_vm.role == 'superuser')?_c('div',{staticClass:"body-2 text-center mb-2"},[_vm._v(" ("+_vm._s(_vm.activeMuseum.museumLocales[0].title)+") ")]):_vm._e(),_c('div',{staticClass:"text-h6 primary--text text-center mb-1"},[_vm._v("Βήμα 3")]),_c('div',{staticClass:"text-h6 text-center title-underline mb-12"},[_vm._v(" Δημιουργία Σεναρίου ")]),_c('div',{staticClass:"inner-wrapper d-flex flex-column"},[_c('Step1Info',{staticClass:"mb-8"}),_c('div',{staticClass:"subtitle-1 font-weight-medium mb-4"},[_vm._v("Ενότητες")]),_c('draggable',{attrs:{"animation":200},model:{value:(_vm.sections),callback:function ($$v) {_vm.sections=$$v},expression:"sections"}},[_c('transition-group',_vm._l((_vm.sections),function(section){return _c('div',{key:section.id,staticClass:"pa-2 primary mb-2 rounded white--text d-flex align-center",class:{
            primary: _vm.openSection && _vm.openSection.id == section.id,
            'warning lighten-1': _vm.openSection && _vm.openSection.id == section.id,
          }},[_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(section.order)+". "+_vm._s(section.title))]),(_vm.openSection && _vm.openSection.id == section.id)?_c('v-btn',{staticClass:"ml-auto",attrs:{"small":""},on:{"click":function($event){return _vm.setOpenSection(null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Κλεισιμο ")],1):_c('v-btn',{staticClass:"ml-auto",attrs:{"small":""},on:{"click":function($event){return _vm.setOpenSection(section)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-expand")]),_vm._v(" Ανοιγμα ")],1)],1)}),0)],1),(_vm.openSection)?[_c('div',{staticClass:"assets-wrapper mt-10"},[_c('draggable',{attrs:{"animation":200},model:{value:(_vm.openSectionAssets),callback:function ($$v) {_vm.openSectionAssets=$$v},expression:"openSectionAssets"}},_vm._l((_vm.openSectionAssets),function(asset){return _c('div',{key:asset.id,staticClass:"item"},[_c('div',{staticClass:"item-content-wrapper rounded"},[_c('div',{staticClass:"item-actions-wrapper d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-checkbox',{staticClass:"ma-0 pa-0 reorder-checkbox",attrs:{"hide-details":"","ripple":false},on:{"change":_vm.onUpdateSectionAssetsOrder},model:{value:(asset.presentationInclude),callback:function ($$v) {_vm.$set(asset, "presentationInclude", $$v)},expression:"asset.presentationInclude"}})],1)]}}],null,true)},[_c('div',{staticClass:"body-2"},[_vm._v("Συμπεριλάβετε στην παρουσίαση")])]),(asset.type == 'video')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"has-sound-icon rounded-bl primary ml-auto"},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(asset.hasSound ? "mdi-music-note" : "mdi-music-note-off")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(asset.hasSound ? "Το βίντεο έχει ήχο" : "Το βίντεο είναι σε σίγαση"))])]):_vm._e()],1),(asset.type == 'image')?_c('img',{staticClass:"item-content",attrs:{"src":(_vm.baseUrl + "/" + (asset.path))}}):_vm._e(),(asset.type == 'video')?_c('div',{staticClass:"item-content video",on:{"click":function($event){return _vm.openVideo(asset.path)}}},[_c('img',{attrs:{"src":asset.thumb}})]):_vm._e()])])}),0)],1)]:_vm._e(),_c('v-btn',{staticClass:"align-self-center mt-10",attrs:{"color":"primary","loading":_vm.loader,"disabled":!_vm.hasChanges},on:{"click":_vm.onSave}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Αποθηκευση ")],1),_c('v-divider',{staticClass:"my-10"}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.onPrev}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" Προηγουμενο ")],1),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","small":"","disabled":_vm.hasChanges,"loading":_vm.submitLoader},on:{"click":_vm.onFinish}},[_vm._v(" "+_vm._s(_vm.isEdit && !_vm.currentProgram.isDraft ? "Ολοκληρωση" : "υποβολη προγραμματος")+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }