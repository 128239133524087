import axios from 'axios'

export default {
    state: {
        programs: [],
        currentProgram: null,
        allowStep2: false,
        allowStep3: false,
        openSection: null
    },
    mutations: {
        getPrograms(state, programs) {
            state.programs = programs;
        },
        setCurrentProgram(state, uniqueCode) {
            const foundProgram = state.programs.find(p => p.uniqueCode == uniqueCode);

            if (foundProgram) state.currentProgram = foundProgram;
        },
        newProgram(state, program) {
            state.currentProgram = program;
            state.allowStep2 = true;
        },
        updateCurrentProgram(state, program) {
            state.currentProgram = {
                ...state.currentProgram,
                ...program
            }
        },
        newSection(state, section) {
            state.currentProgram.sections.push(section);
        },
        deleteSection(state, updatedSections) {
            state.currentProgram.sections = updatedSections;
        },
        setAllowStep3(state, allowStep3) {
            state.allowStep3 = allowStep3;
        },
        updateSectionsOrder(state, sections) {
            state.currentProgram.sections = sections.map((section, index) => {

                return {
                    ...section,
                    order: index + 1,
                }
            });
        },
        setOpenSection(state, section) {
            state.openSection = section;
        },
        updateSectionAssetsOrder(state, sectionAssets) {
            state.currentProgram.sections = state.currentProgram.sections.map((section) => {

                if (section.id == state.openSection.id) {
                    const updatedAssets = sectionAssets.map((asset, index) => {
                        return {
                            ...asset,
                            order: index + 1
                        }
                    })

                    return {
                        ...section,
                        sectionAssets: updatedAssets
                    }

                } else {
                    return {
                        ...section,
                    }
                }
            });

            state.openSection = state.currentProgram.sections.find(section => section.id == state.openSection.id);
        },
        reorder(state, updatedProgram) {
            state.currentProgram = {
                ...state.currentProgram,
                ...updatedProgram
            };
        },
        editSection(state, updatedSection) {
            state.currentProgram.sections = state.currentProgram.sections.map(section => {
                if (section.id != updatedSection.id) {
                    return {
                        ...section
                    }
                } else {
                    return {
                        ...updatedSection
                    }
                }
            })
        },
        resetProgramState(state) {
            state.currentProgram = null;
            state.allowStep2 = false;
            state.allowStep3 = false;
            state.openSection = null
        },
    },
    actions: {
        async getPrograms({ commit, rootState }) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/getPrograms`, {
                    ...(rootState.auth.role == "superuser" && {
                        museumId: rootState.superuser.activeMuseum.id
                    })
                });

                commit("getPrograms", res.data.programs);
            } catch (e) {
                throw e;
            }
        },
        async newProgram({ commit, rootState }, program) {
            if (rootState.auth.role == "superuser") {
                program.set("museumId", rootState.superuser.activeMuseum.id)
            }

            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/newProgram`, program);

                commit("newProgram", res.data.program);
            } catch (e) {
                throw e;
            }
        },
        async newSection({ rootState }, section) {
            try {
                if (rootState.auth.role == "superuser") {
                    section.set("museumId", rootState.superuser.activeMuseum.id)
                }

                await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/newSection`, section);
            } catch (e) {
                throw e;
            }
        },
        async deleteSection({ commit, rootState }, section) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/deleteSection`, {
                    sectionId: section.id,
                    programId: section.programId,
                    ...(rootState.auth.role == "superuser" && {
                        museumId: rootState.superuser.activeMuseum.id
                    })
                });

                commit("deleteSection", res.data.updatedSections);
            } catch (e) {
                throw e;
            }
        },
        async reorder({ commit, state, rootState }) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/reorder`, {
                    programId: state.currentProgram.id,
                    sections: state.currentProgram.sections,
                    ...(rootState.auth.role == "superuser" && {
                        museumId: rootState.superuser.activeMuseum.id
                    })
                });

                commit("reorder", res.data.updatedProgram);
            } catch (e) {
                throw e;
            }
        },
        async submitNewProgram({ state, rootState, commit }) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/submitNewProgram`, {
                    programId: state.currentProgram.id,
                    ...(rootState.auth.role == "superuser" && {
                        museumId: rootState.superuser.activeMuseum.id
                    })
                });

                commit("toggleSnackbar", {
                    open: true,
                    text: "Το πρόγραμμα προστέθηκε επιτυχώς!",
                    color: "success"
                })
            } catch (e) {
                throw e;
            }
        },
        async editProgram({ commit, rootState }, program) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/editProgram`, {
                    ...program,
                    ...(rootState.auth.role == "superuser" && {
                        museumId: rootState.superuser.activeMuseum.id
                    })
                });

                commit("updateCurrentProgram", res.data.updatedProgram);
            } catch (e) {
                throw e;
            }
        },
        async editSection({ rootState }, section) {
            if (rootState.auth.role == "superuser") {
                section.set("museumId", rootState.superuser.activeMuseum.id)
            }

            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/editSection`, section);
            } catch (e) {
                throw e;
            }
        },
        async sectionSounds({ rootState, commit }, sounds) {
            if (rootState.auth.role == "superuser") {
                sounds.set("museumId", rootState.superuser.activeMuseum.id)
            }

            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/sectionSounds`, sounds);

                commit("editSection", res.data.section)
            } catch (e) {
                throw e;
            }
        },
        async deleteDraft({ rootState, commit }, programId) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/deleteDraft`, {
                    ...(rootState.auth.role == "superuser" && {
                        museumId: rootState.superuser.activeMuseum.id
                    }),
                    programId
                });

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Το προσχέδιο του προγράμματος έχει διαγραφεί!"
                })
            } catch (e) {
                throw e;
            }
        }
    },
}
