<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <v-btn
      icon
      color="primary"
      @click="$router.push({ path: '/superuser' })"
      class="close-button"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <form class="inner-wrapper d-flex flex-column" @submit.prevent="submit">
      <v-text-field
        outlined
        label="Όνομα Μουσείου"
        v-model="title"
        :error-messages="titleErrors"
      ></v-text-field>

      <v-btn
        class="align-self-center"
        small
        color="primary"
        type="submit"
        :disabled="!hasChanges"
        :loading="loader"
      >
        <v-icon left>mdi-content-save</v-icon>
        Αποθηκευση
      </v-btn>
    </form>

    <div class="d-flex justify-center align-center">
      <v-btn
        large
        class="mr-4"
        width="180px"
        color="primary"
        depressed
        :to="`/superuser/museum/${$route.params.id}/users`"
      >
        <v-icon left> mdi-account-multiple</v-icon> Χρηστες
      </v-btn>

      <v-btn
        large
        width="180px"
        color="primary"
        depressed
        :to="`/superuser/museum/${$route.params.id}/programs`"
      >
        <v-icon left> mdi-folder-multiple</v-icon>
        Προγραμματα</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required, email, sameAs } from "vuelidate/lib/validators";

const uniqueTitle = function () {
  return !this.titleExists;
};

export default {
  data() {
    return {
      title: "",
      initialTitle: "",
      loader: false,
      titleExists: false,
    };
  },
  validations: {
    title: { required, uniqueTitle },
  },
  computed: {
    ...mapState({
      activeMuseum: (state) => state.superuser.activeMuseum,
    }),
    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      if (!this.$v.title.uniqueTitle)
        errors.push("Υπάρχει ήδη μουσείο με αυτόν τον τίτλο.");
      return errors;
    },
    hasChanges() {
      return this.title !== this.initialTitle;
    },
  },
  created() {
    this.title = this.activeMuseum.museumLocales[0].title;
    this.initialTitle = this.activeMuseum.museumLocales[0].title;
  },
  methods: {
    ...mapActions(["editMuseum"]),
    async submit() {
      this.titleExists = false;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        await this.editMuseum(this.title);

        this.title = this.activeMuseum.museumLocales[0].title;
        this.initialTitle = this.activeMuseum.museumLocales[0].title;

        this.$v.$reset();
      } catch (e) {
        console.log(e);

        if (e.response.status == 400 && e.response.data.data.title) {
          this.titleExists = true;
        }
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-wrapper {
  margin: 80px 0;
}

.title-underline {
  margin-bottom: 120px;
}
</style>