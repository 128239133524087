<template>
  <div class="d-flex align-center header-wrapper pl-4">
    <div class="mr-5 py-1">
      <div class="body-2 font-weight-medium mb-1">
        {{ program.museum.museumLocales[0].title }}
      </div>

      <div class="d-flex">
        <div class="body-2 mr-2">Πρόγραμμα:</div>
        <div class="body-2">{{ program.programLocales[0].title }}</div>
      </div>
    </div>

    <div class="primary px-2 d-flex align-center align-self-stretch">
      <div class="body-1 white--text font-weight-medium">
        {{ duration | minuteSeconds }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      duration: 0,
      interval: null,
    };
  },
  computed: {
    ...mapState({
      roomContent: (state) => state.shadow.roomContent,
      program: (state) => state.shadow.program,
      roomCreatedAt: (state) => state.twilio.roomCreatedAt,
    }),
  },
  created() {
    const countFrom = moment.utc(this.roomCreatedAt).toDate();

    const now = moment(new Date());

    this.duration = moment(now).diff(countFrom, "seconds");

    this.interval = setInterval(() => {
      this.duration++;
    }, 1000);
  },
  methods: {
    onLogoClick() {
      window.open("http://www.museotek.net");
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  border-bottom: 1px solid #e0e0e0;
}

.logo {
  height: 30px;
  cursor: pointer;
}
</style>