import store from '@/store'

import Shadow from '@/views/shadow/Shadow';
import Room from '@/views/shadow/Room.vue'
import Camera from '@/views/shadow/Camera.vue'
import ContentWrapper from '@/views/shadow/ContentWrapper.vue'
import Image from '@/views/shadow/Image.vue'
import Video from '@/views/shadow/Video.vue'

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next()
        return
    }

    next('/login');
}

export default [
    {
        path: '/shadow',
        component: Shadow,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/room',
        component: Room,
        beforeEnter(to, from, next) {
            if (store.state.twilio.room) {
                next();
            } else {
                next('/');
            }
        },
        children: [
            {
                path: "/",
                redirect: 'camera'
            },
            {
                path: 'camera',
                component: Camera
            },
            {
                path: 'content',
                component: ContentWrapper,
                children: [
                    {
                        path: "/",
                        redirect: '/room/camera'
                    },
                    {
                        path: 'image',
                        component: Image
                    },
                    {
                        path: 'video',
                        component: Video
                    },
                ]
            }
        ]
    },
]