import axios from 'axios'

export default {
    state: {
        museums: [],
        museumsFetched: false,
        museumsLoader: false,
        activeMuseum: null
    },
    mutations: {
        getMuseums(state, museums) {
            state.museums = museums;
        },
        setMuseumsFetched(state, museumsFetched) {
            state.museumsFetched = museumsFetched;
        },
        toggleMuseumsLoader(state, museumsLoader) {
            state.museumsLoader = museumsLoader;
        },
        setActiveMuseum(state, museumId) {
            const found = state.museums.find(museum => museum.id == museumId);

            if (found) {
                state.activeMuseum = { ...found }
            }
        },
        editMuseum(state, title) {
            state.activeMuseum.museumLocales[0].title = title;
            state.museumsFetched = false;
        }
    },
    actions: {
        async getMuseums({ commit, state }, refresh = false) {
            if (state.museumsFetched && !refresh) return;

            commit("toggleMuseumsLoader", true);

            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/super/getMuseums`);

                commit("getMuseums", res?.data?.museums);
                commit("setMuseumsFetched", true);
            } catch (e) {
                throw e;
            }

            commit("toggleMuseumsLoader", false);
        },
        async addMuseum({ dispatch, commit }, title) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/super/addMuseum`, {
                    title
                })

                const museum = res.data.museum;

                await dispatch("getMuseums", true);

                commit("toggleSnackbar", {
                    text: `Το μουσείο '${museum.museumLocales[0].title}' έχει εισαχθεί επιτυχώς. Μπορείτε να προσθέσετε χρήστες και προγράμματα!`,
                    color: "success",
                    open: true
                });

                return museum.id;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async createUser({ state }, user) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/super/createUser`, {
                    ...user,
                    museumId: state.activeMuseum.id
                });
            } catch (e) {
                throw e;
            }
        },
        async editMuseum({ state, commit }, title) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/super/editMuseum`, {
                    museumId: state.activeMuseum.id,
                    title
                });

                commit("editMuseum", res.data.updatedMuseum.title);
            } catch (e) {
                throw e;
            }
        }
    },
}
