<template>
  <div class="d-flex py-1 pl-4 pr-1 camera-buttons">
    <v-btn icon color="white" class="error mr-3" @click="dialog = true">
      <v-icon> mdi-phone-hangup </v-icon>
    </v-btn>

    <v-btn icon color="white" class="primary" @click="toggleChat(!chat)">
      <v-icon>mdi-facebook-messenger</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="500">
      <div class="white pa-3">
        <div class="body-1 text-center mb-4">
          Θέλετε να αποχωρήσετε από την παρουσίαση?
        </div>

        <div class="d-flex justify-center">
          <v-btn color="error" depressed class="mr-3" @click="onLeave"
            >Αποχωρηση</v-btn
          >

          <v-btn color="primary" depressed @click="dialog = false"
            >Παραμονη</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.role,
      chat: (state) => state.base.chat,
    }),
  },
  methods: {
    ...mapMutations(["setAllowLeave", "toggleChat"]),
    onLeave() {
      this.setAllowLeave(true);

      this.$router.push({
        path: `${this.role == "superuser" ? "/superuser" : "/"}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>