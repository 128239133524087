<template>
  <div class="component-wrapper d-flex flex-column">
    <div class="text-h6 text-center title-underline mb-5">Μουσεία</div>

    <v-btn
      class="align-self-start mb-12"
      small
      color="primary"
      @click="$router.push({ path: '/superuser/new-museum' })"
    >
      <v-icon left small>mdi-plus</v-icon>
      Νεο Μουσειο
    </v-btn>

    <v-progress-circular
      indeterminate
      color="primary"
      width="3"
      size="55"
      class="mx-auto mt-12"
      v-if="museumsLoader"
    ></v-progress-circular>

    <template v-else>
      <div class="body-1" v-if="!museums.length">
        Προσθέστε το πρώτο σας μουσείο!
      </div>

      <v-row>
        <v-col
          xl="3"
          lg="4"
          md="6"
          sm="6"
          cols="12"
          v-for="museum in museums"
          :key="museum.id"
        >
          <MuseumCard :museum="museum" />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MuseumCard from "@/views/superUser/MuseumCard";

export default {
  components: {
    MuseumCard,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      museums: (state) => state.superuser.museums,
      museumsLoader: (state) => state.superuser.museumsLoader,
    }),
  },
  async created() {
    try {
      await this.getMuseums();
    } catch (e) {}
  },
  methods: {
    ...mapActions(["getMuseums"]),
  },
};
</script>

<style lang="scss" scoped>
</style>