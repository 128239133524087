<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <Header class="mb-5" />

    <div class="d-flex justify-center">
      <div class="guide elevation-5 rounded mr-10" ref="guide">
        <div
          class="
            text-center
            pa-1
            body-1
            font-weight-medium
            primary--text
            camera-header
          "
        >
          ΞΕΝΑΓΟΣ
        </div>

        <div
          class="track-msg white--text body-1 text-center"
          v-if="guideConnected && !guideVideoState"
        >
          Η κάμερά του ξεναγού είναι απενεργοποιημένη
        </div>

        <div class="track-msg body-1 text-center" v-if="!guideConnected">
          Ο ξεναγός δεν έχει συνδεθεί
        </div>
      </div>

      <div class="school elevation-5 rounded" ref="school">
        <div
          class="
            text-center
            pa-1
            body-1
            font-weight-medium
            primary--text
            camera-header
          "
        >
          ΣΧΟΛΕΙΟ
        </div>

        <div
          class="track-msg white--text body-1 text-center"
          v-if="schoolConnected && !schoolVideoState"
        >
          Η κάμερά του σχολείου είναι απενεργοποιημένη
        </div>

        <div class="track-msg body-1 text-center" v-if="!schoolConnected">
          Το σχολείο δεν έχει συνδεθεί
        </div>
      </div>
    </div>

    <Buttons class="mt-5" />
  </div>
</template>

<script>
import Header from "@/views/shadow/Header";
import Buttons from "@/views/shadow/Buttons";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Header,
    Buttons,
  },
  computed: {
    ...mapState({
      triggersetListeners: (state) => state.twilio.triggersetListeners,
      guideConnected: (state) => state.twilio.guideConnected,
      schoolConnected: (state) => state.twilio.schoolConnected,
      guideVideoState: (state) => state.twilio.guideVideoState,
      schoolVideoState: (state) => state.twilio.schoolVideoState,
      allowLeave: (state) => state.shadow.allowLeave,
    }),
  },
  mounted() {
    this.changeRefs({
      guide: this.$refs.guide,
      school: this.$refs.school,
    });

    if (!this.triggersetListeners) this.setTriggersetListeners();
  },
  methods: {
    ...mapActions(["changeRefs", "detachTracks"]),
    ...mapMutations(["setTriggersetListeners", "setAllowLeave"]),
  },
  destroyed() {
    this.detachTracks();
  },
  beforeRouteLeave(to, from, next) {
    if (this.allowLeave) {
      this.setAllowLeave(false);
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.camera-header {
  border-bottom: 1px solid #eee;
}
</style>