<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <v-btn
      icon
      color="primary"
      @click="$router.push({ path: '/superuser' })"
      class="close-button"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="text-h6 text-center title-underline mb-12">Νέο Μουσείο</div>

    <form class="inner-wrapper d-flex flex-column" @submit.prevent="submit">
      <v-text-field
        outlined
        label="Όνομα Μουσείου"
        v-model="title"
        :error-messages="titleErrors"
      ></v-text-field>

      <v-btn
        color="primary"
        class="align-self-center"
        small
        depressed
        type="submit"
        :loading="loader"
        >Προσθηκη</v-btn
      >
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

const uniqueMuseum = function () {
  return !this.museumExists;
};

export default {
  data() {
    return {
      title: "",
      loader: false,
      museumExists: false,
    };
  },
  validations: {
    title: { required, uniqueMuseum },
  },
  computed: {
    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      if (!this.$v.title.required)
        errors.push("Αυτό το πεδίο είναι απαραίτητο");
      if (!this.$v.title.uniqueMuseum)
        errors.push("Υπάρχει ήδη μουσείο με αυτό το όνομα.");
      return errors;
    },
  },
  methods: {
    ...mapActions(["addMuseum"]),
    async submit() {
      this.museumExists = false;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        const museumId = await this.addMuseum(this.title);

        this.$router.push({ path: `/superuser/museum/${museumId}` });
      } catch (e) {
        if (e?.response?.status == 400 && e?.response?.data?.data?.title) {
          this.museumExists = true;
        }
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>