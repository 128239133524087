<template>
  <v-navigation-drawer v-model="chat" absolute temporary right width="25%">
    <div class="chat-wrapper d-flex flex-column grey lighten-4 pa-5">
      <div class="text-h6 mb-4 text-center">Ερωτήσεις</div>

      <template v-if="questions.length">
        <div class="body-2 text-center mb-1">Έχουν απαντηθεί</div>

        <div class="body-2 font-weight-medium text-center primary--text">
          {{ answered }}/{{ questions.length }}
        </div>
      </template>

      <div class="questions-wrapper" ref="questionsWrapper">
        <div
          class="d-flex flex-column"
          v-for="(question, index) in questions"
          :key="index"
        >
          <div class="body-1 mr-2">
            {{ question.question }}
          </div>

          <v-divider class="my-2"></v-divider>
        </div>

        <div class="body-1 text-center" v-if="!questions.length">
          Δεν έχουν υποβληθεί ερωτήσεις
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  computed: {
    chat: {
      get() {
        return this.$store.state.base.chat;
      },
      set(value) {
        if (!value) this.toggleChat(value);
      },
    },
    ...mapState({
      questions: (state) => state.shadow.questions,
    }),
    answered() {
      return this.questions.filter((q) => q.answered).length;
    },
  },
  methods: {
    ...mapMutations(["toggleChat"]),
  },
  watch: {
    chat(newValue) {
      if (newValue) {
        this.$vuetify.goTo(this.$refs.questionsWrapper.clientHeight, {
          container: ".questions-wrapper",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-wrapper {
  height: 100%;
}

.questions-wrapper {
  flex: 1 0 auto;
  overflow-y: auto;
  height: 0px;
}
</style>