<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <v-btn
      icon
      color="primary"
      @click="$router.push({ path: `/superuser/museum/${activeMuseum.id}` })"
      class="close-button"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="text-h6 text-center title-underline mb-12">
      {{ activeMuseum.museumLocales[0].title }}
    </div>

    <v-data-table
      :headers="headers"
      :items="users"
      sort-by="calories"
      class="elevation-1"
      :loading="museumsLoader"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-icon left> mdi-account-multiple</v-icon>
            Χρήστες
          </v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>

          <v-btn color="primary" small depressed @click="dialog = true">
            <v-icon left> mdi-plus</v-icon>
            Προσθηκη</v-btn
          >
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500">
      <form
        class="white d-flex flex-column pa-5"
        novalidate
        @submit.prevent="submit"
      >
        <div class="text-h6 text-center mb-5">Νέος Χρήστης</div>

        <v-text-field
          outlined
          label="Email"
          prepend-inner-icon="mdi-email"
          class="mb-1"
          type="email"
          v-model="email"
          :error-messages="emailErrors"
        ></v-text-field>

        <v-text-field
          outlined
          label="Κωδικός"
          prepend-inner-icon="mdi-lock"
          class="mb-1"
          type="password"
          v-model="password"
          :error-messages="passwordErrors"
        ></v-text-field>

        <v-text-field
          outlined
          label="Επιβεβαίωση Κωδικού"
          prepend-inner-icon="mdi-lock"
          class="mb-1"
          type="password"
          v-model="confirmPassword"
          :error-messages="confirmPasswordErrors"
        ></v-text-field>

        <v-btn
          small
          color="primary"
          depressed
          class="align-self-center"
          type="submit"
          :loading="loader"
          >Προσθηκη</v-btn
        >
      </form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";
import { required, email, sameAs } from "vuelidate/lib/validators";

const uniqueEmail = function () {
  return !this.emailExists;
};

export default {
  data() {
    return {
      headers: [
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        {
          text: "Ημ/νία Δημιουργίας",
          sortable: false,
          value: "createdAt",
        },
      ],
      dialog: false,
      email: "",
      password: "",
      confirmPassword: "",
      emailExists: false,
      loader: false,
    };
  },
  validations: {
    email: { required, email, uniqueEmail },
    password: { required },
    confirmPassword: { required, sameAsPassword: sameAs("password") },
  },
  computed: {
    ...mapState({
      activeMuseum: (state) => state.superuser.activeMuseum,
      museumsLoader: (state) => state.superuser.museumsLoader,
    }),
    users() {
      return this.activeMuseum.users.map((user) => {
        return {
          ...user,
          createdAt: moment(user.createdAt).format("DD/MM/YYYY"),
        };
      });
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required)
        errors.push("Αυτό το πεδίο είναι απαραίτητο");
      if (!this.$v.email.email)
        errors.push("Εισαγάγετε μια έγκυρη μορφή email");
      if (!this.$v.email.uniqueEmail)
        errors.push("Υπάρχει ήδη χρήστης με αυτό το email.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required)
        errors.push("Αυτό το πεδίο είναι απαραίτητο");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      if (!this.$v.confirmPassword.required)
        errors.push("Αυτό το πεδίο είναι απαραίτητο");
      if (!this.$v.confirmPassword.sameAsPassword)
        errors.push("Οι κωδικοί πρόσβασης δεν ταιριάζουν");
      return errors;
    },
  },
  methods: {
    ...mapActions(["createUser", "getMuseums"]),
    ...mapMutations(["setActiveMuseum"]),
    async submit() {
      this.emailExists = false;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        await this.createUser({
          email: this.email,
          password: this.password,
        });

        await this.getMuseums(true);

        this.setActiveMuseum(this.activeMuseum.id);

        this.dialog = false;

        this.email = "";
        this.password = "";
        this.confirmPassword = "";
        this.$v.$reset();
      } catch (e) {
        console.log(e);

        if (e.response.status == 400 && e.response.data.data.email) {
          this.emailExists = true;
        }
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>