
export default {
    state: {
        snackbar: {
            open: false,
            color: null,
            text: ''
        },
        chat: false
    },
    mutations: {
        toggleSnackbar(state, snackbar) {
            state.snackbar = {
                ...state.snackbar,
                ...snackbar
            };
        },
        toggleChat(state, chat) {
            state.chat = chat;
        }
    },
    actions: {
    },
}
