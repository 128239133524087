import store from '@/store'

import Home from '@/views/home/Home.vue'
import Program from '@/views/program/Program'
import Step1 from '@/views/program/step1/Step1'
import Step2 from '@/views/program/step2/Step2'
import Step3 from '@/views/program/step3/Step3'

const isAdmin = (to, from, next) => {
    if (!store.getters.isLoggedIn) {
        next('/login')
        return;
    }

    if (store.state.auth.role == 'superuser') {
        next('/superuser');
        return;
    }

    next()
}

export default [
    // ======= home page =======
    {
        path: '/',
        component: Home,
        beforeEnter: isAdmin,
    },
    {
        path: '/program',
        component: Program,
        beforeEnter: isAdmin,
        children: [
            // // ====== NEW PROGRAM ======
            // // ====== STEP 1 ======
            // {
            //     path: 'new/step1',
            //     component: Step1
            // },
            // // ====== STEP 2 ======
            // {
            //     path: 'new/step2',
            //     component: Step2,
            //     beforeEnter: (to, from, next) => {
            //         if (store.state.admin.allowStep2) {
            //             next();
            //         } else {
            //             next('/');
            //         }
            //     }
            // },
            // // ====== STEP 3 ======
            // {
            //     path: 'new/step3',
            //     component: Step3,
            //     beforeEnter: (to, from, next) => {
            //         if (store.state.admin.allowStep3) {
            //             next();
            //         } else {
            //             next('/');
            //         }
            //     }
            // },
            // ====== EDIT PROGRAM ======
            // ====== STEP 1 (EDIT) ======
            {
                path: 'edit/:id/step1',
                component: Step1,
                beforeEnter: (to, from, next) => {
                    store.commit("setCurrentProgram", to.params?.id);

                    if (store.state.admin.currentProgram) {
                        next();
                    } else {
                        next('/');
                    }
                }
            },
            // ====== STEP 2 (EDIT) ======
            {
                path: 'edit/:id/step2',
                component: Step2,
                beforeEnter: (to, from, next) => {
                    store.commit("setCurrentProgram", to.params?.id);

                    if (store.state.admin.currentProgram) {
                        next();
                    } else {
                        next('/');
                    }
                }
            },
            // ====== STEP 3 (EDIT) ======
            {
                path: 'edit/:id/step3',
                component: Step3,
                beforeEnter: (to, from, next) => {
                    store.commit("setCurrentProgram", to.params?.id);

                    if (store.state.admin.currentProgram) {
                        next();
                    } else {
                        next('/');
                    }
                }
            },
        ]
    },
]
