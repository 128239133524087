import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { firestore } from '@/firestore';
import axios from "axios";

export default {
    state: {
        program: null,
        roomContent: null,
        questions: [],
        videoPlayer: null,
        isVideoPlaying: false,
        allowLeave: false
    },
    mutations: {
        ...vuexfireMutations,
        getContent(state, program) {
            state.program = program;
        },
        setVideoPlayer(state, videoPlayer) {
            state.videoPlayer = videoPlayer;
        },
        setIsVideoPlaying(state, isVideoPlaying) {
            state.isVideoPlaying = isVideoPlaying;
        },
        setAllowLeave(state, allowLeave) {
            state.allowLeave = allowLeave;
        },
        resetSchoolState(state) {
            state.program = null;
            state.roomContent = null;
            state.videoPlayer = null;
            state.isVideoPlaying = false;
            state.allowLeave = false;
        }
    },
    actions: {
        async getContent({ commit }, roomName) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/room/connect`, {
                    roomName,
                    role: "shadow"
                });

                commit("getContent", res.data.program);

                return {
                    accessToken: res.data.roomData.accessToken,
                    roomSid: res.data.roomData.roomSid,
                    roomCreatedAt: res.data.roomData.roomCreatedAt
                }
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        bindRoomContent: firestoreAction(async ({ rootState, bindFirestoreRef }) => {
            try {
                await bindFirestoreRef('roomContent', firestore.collection('rooms').doc(rootState.twilio.room.sid));
                await bindFirestoreRef('questions', firestore.collection(`rooms/${rootState.twilio.room.sid}/questions`).orderBy("asked", "asc"));
            } catch (e) {
                throw e;
            }
        }),
        unbindRoomContent: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('roomContent');
            unbindFirestoreRef('questions');
        }),
    }
}