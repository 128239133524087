import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth';
import admin from './admin';
import base from './base';
import superuser from './superuser';
import shadow from './shadow';
import twilio from './twilio';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    admin,
    base,
    superuser,
    shadow,
    twilio
  }
})
