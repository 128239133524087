import axios from "axios";
import twilio from "twilio-video";

export default {
    state: {
        room: null,
        roomCreatedAt: null,
        triggersetListeners: false,
        guideRef: null,
        schoolRef: null,
        guideConnected: false,
        schoolConnected: false,
        guideVideoState: null,
        schoolVideoState: null
    },
    mutations: {
        setRoom(state, payload) {
            state.room = payload.room;
            state.roomCreatedAt = payload.roomCreatedAt;
        },
        setTriggersetListeners(state) {
            state.triggersetListeners = true;
        },
        setRefs(state, refs) {
            state.guideRef = refs.guide;
            state.schoolRef = refs.school;
        },
        setGuideConnected(state, connected = true) {
            state.guideConnected = connected;
        },
        setSchoolConnected(state, connected = true) {
            state.schoolConnected = connected;
        },
        setGuideVideoState(state, guideVideoState) {
            state.guideVideoState = guideVideoState;
        },
        setSchoolVideoState(state, schoolVideoState) {
            state.schoolVideoState = schoolVideoState;
        },
        resetTwilioState(state) {
            state.room = null;
            state.roomCreatedAt = null;
            state.triggersetListeners = false;
            state.guideRef = null;
            state.schoolRef = null;
            state.guideConnected = false;
            state.schoolConnected = false;
            state.guideVideoState = null;
            state.schoolVideoState = null;
        }
    },
    actions: {
        async getLocalTracks({ }) {
            try {
                const localTracks = await twilio.createLocalTracks({
                    audio: false,
                    video: false
                })

                return localTracks;
            } catch (e) {
                throw e;
            }
        },
        async connectToRoom({ commit, dispatch }, roomName) {
            try {
                const roomInfo = await dispatch('getContent', roomName);

                const tracks = await dispatch('getLocalTracks');

                const room = await twilio.connect(roomInfo.accessToken, { tracks });

                commit('setRoom', {
                    room,
                    roomCreatedAt: roomInfo.roomCreatedAt
                });

                await dispatch("bindRoomContent");
            } catch (e) {
                if (e?.response?.status == 400) {
                    if (e.response.data.data.program) {
                        commit("toggleSnackbar", {
                            open: true,
                            color: "error",
                            text: 'Δεν υπάρχει ενεργό πρόγραμμα ή το πρόγραμμα δεν έχει ξεκινήσει ακόμα. Σας θυμίζουμε ότι μπορείτε να συνδεθείτε το νωρίτερο 10 λεπτά πριν την έναρξη ενός προγράμματος'
                        })
                    }
                } else {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: 'Δεν μπορέσαμε να σας συνδέσουμε στην παρουσίαση. Δοκιμάστε άλλον κωδικό η δοκιμάστε ξανά αργότερα.'
                    })
                }

                throw e;
            }
        },
        setListeners({ state, commit }) {
            //====== HANDLE CONNECTED PARTICIPANTS ======
            state.room.participants.forEach((participant) => {
                console.log(`Participant "${participant.identity}" is already connected`);

                participant.on("trackSubscribed", (track) => {
                    if (participant.identity == 'guide') {
                        state.guideRef.appendChild(track.attach());
                    } else {
                        state.schoolRef.appendChild(track.attach());
                    }

                    if (track.kind == 'video') {
                        if (participant.identity == 'guide') {
                            commit('setGuideVideoState', track.isEnabled)
                        } else {
                            commit("setSchoolVideoState", track.isEnabled)
                        }
                    }
                });

                participant.on("trackEnabled", (publication) => {
                    if (publication.kind == 'video') {
                        if (participant.identity == 'guide') {
                            commit('setGuideVideoState', publication.isTrackEnabled)
                        } else {
                            commit("setSchoolVideoState", publication.isTrackEnabled)
                        }
                    }
                });

                participant.on("trackDisabled", (publication) => {
                    if (publication.kind == 'video') {
                        if (participant.identity == 'guide') {
                            commit('setGuideVideoState', publication.isTrackEnabled)
                        } else {
                            commit("setSchoolVideoState", publication.isTrackEnabled)
                        }
                    }
                });

                if (participant.identity == 'guide') {
                    commit("setGuideConnected");
                } else {
                    commit("setSchoolConnected");
                }
            });

            //====== HANDLE REMOTE PARTICIPANTS EVENTS ======
            //------ participant connected ------

            state.room.on("participantConnected", (participant) => {
                console.log(`Participant "${participant.identity}" connected`);

                participant.tracks.forEach((publication) => {
                    if (publication.isSubscribed) {
                        const track = publication.track;

                        if (participant.identity == 'guide') {
                            state.guideRef.appendChild(track.attach());
                        } else {
                            state.schoolRef.appendChild(track.attach());
                        }

                        if (publication.kind == 'video') {
                            if (participant.identity == 'guide') {
                                commit('setGuideVideoState', publication.isTrackEnabled)
                            } else {
                                commit("setSchoolVideoState", publication.isTrackEnabled)
                            }
                        }
                    }
                });

                participant.on("trackSubscribed", (track) => {
                    if (track.kind == 'video') {
                        if (participant.identity == 'guide') {
                            commit('setGuideVideoState', track.isEnabled)
                        } else {
                            commit("setSchoolVideoState", track.isEnabled)
                        }
                    }

                    if (participant.identity == 'guide') {
                        state.guideRef.appendChild(track.attach());
                    } else {
                        state.schoolRef.appendChild(track.attach());
                    }
                });

                participant.on("trackDisabled", (publication) => {
                    if (publication.kind == 'video') {
                        if (participant.identity == 'guide') {
                            commit('setGuideVideoState', publication.isTrackEnabled)
                        } else {
                            commit("setSchoolVideoState", publication.isTrackEnabled)
                        }
                    }
                });

                participant.on("trackEnabled", (publication) => {
                    if (publication.kind == 'video') {
                        if (participant.identity == 'guide') {
                            commit('setGuideVideoState', publication.isTrackEnabled)
                        } else {
                            commit("setSchoolVideoState", publication.isTrackEnabled)
                        }
                    }
                });

                if (participant.identity == 'guide') {
                    commit("setGuideConnected");
                } else {
                    commit("setSchoolConnected");
                }
            });

            //------ participant disconnected ------
            state.room.on("participantDisconnected", (participant) => {
                console.log(`Participant disconnected: ${participant.identity}`);

                if (participant.identity == 'guide') {
                    const video = state.guideRef.getElementsByTagName("video")[0];
                    const audio = state.guideRef.getElementsByTagName("audio")[0];

                    video.pause();
                    audio.pause();
                    state.guideRef.removeChild(video);
                    state.guideRef.removeChild(audio);

                    commit("setGuideConnected", false);
                } else {
                    const video = state.schoolRef.getElementsByTagName("video")[0];
                    const audio = state.schoolRef.getElementsByTagName("audio")[0];

                    video.pause();
                    audio.pause();
                    state.schoolRef.removeChild(video);
                    state.schoolRef.removeChild(audio);

                    commit("setSchoolConnected", false);
                }
            });
        },
        changeRefs({ commit, dispatch }, refs) {
            commit('setRefs', refs);
            dispatch('attachTracks');
        },
        attachTracks({ commit, state }) {
            state.room.localParticipant.videoTracks.forEach((publication) => {
                state.localRef.appendChild(publication.track.attach());
            });

            state.room.participants.forEach((participant) => {
                participant.tracks.forEach((publication) => {
                    if (publication.isSubscribed) {
                        const track = publication.track;

                        if (participant.identity == 'guide') {
                            state.guideRef.appendChild(track.attach());
                        } else {
                            state.schoolRef.appendChild(track.attach());
                        }

                        if (publication.kind == 'video') {
                            if (participant.identity == 'guide') {
                                commit('setGuideVideoState', publication.isTrackEnabled)
                            } else {
                                commit("setSchoolVideoState", publication.isTrackEnabled)
                            }
                        }
                    }

                    if (participant.identity == 'guide') {
                        commit("setGuideConnected");
                    } else {
                        commit("setSchoolConnected");
                    }
                });
            })
        },
        detachTracks({ state }) {
            state.room.localParticipant.videoTracks.forEach((track) => {
                track.track.detach()
            });

            state.room.participants.forEach((participant) => {
                participant.tracks.forEach((publication) => {
                    if (publication.isSubscribed) {
                        publication.track.detach();
                    }
                });
            });
        },
        disconnect({ state, commit, dispatch }) {
            state.room.disconnect();

            commit("resetTwilioState");
            commit("resetSchoolState");

            dispatch("unbindRoomContent");
        }
    }
}
