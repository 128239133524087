<template>
  <div class="component-wrapper">
    <div class="image-wrapper flex-grow-1">
      <img
        :src="`${baseUrl}/${roomContent.openedContent}`"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      loader: true,
    };
  },
  computed: {
    ...mapState({
      roomContent: (state) => state.shadow.roomContent,
      allowLeave: (state) => state.shadow.allowLeave,
    }),
  },
  methods: {
    ...mapMutations(["setAllowLeave"]),
  },
  beforeRouteLeave(to, from, next) {
    if (this.allowLeave) {
      this.setAllowLeave(false);
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  position: relative;
  height: 100%;
}

img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>