<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        color="grey lighten-3"
        class="font-weight-medium"
      >
        Στοιχεία Προγράμματος
      </v-expansion-panel-header>

      <v-expansion-panel-content color="grey lighten-5">
        <v-row class="pt-5">
          <v-col cols="6">
            <div class="subtitle-1 font-weight-medium">Τίτλος</div>
          </v-col>
          <v-col cols="6">
            <div class="body-1 text-end" v-if="currentProgram">
              {{ currentProgram.programLocales[0].title }}
            </div>
          </v-col>

          <v-col cols="6">
            <div class="subtitle-1 font-weight-medium">Μοναδικός Κωδικός</div>
          </v-col>
          <v-col cols="6">
            <div class="body-1 text-end" v-if="currentProgram">
              {{ currentProgram.uniqueCode }}
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      currentProgram: (state) => state.admin.currentProgram,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>