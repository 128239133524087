<template>
  <div class="elevation-3 rounded new-section-wrapper">
    <div class="subtitle-1 font-weight-medium grey lighten-2 text-center">
      {{
        isEdit && section ? section.order : currentProgram.sections.length + 1
      }}.
      {{
        isEdit && section ? `${section.title} (επεξεργασία)` : "(Νέα ενότητα)"
      }}
    </div>

    <form class="pa-5" @submit.prevent="submit">
      <v-text-field
        outlined
        dense
        label="Τίτλος"
        class="mb-2"
        v-model="title"
        :error-messages="titleErrors"
      ></v-text-field>

      <PhotoInput
        class="mb-2"
        :photosErrors="photosErrors"
        :existingPhotosPreviews="existingPhotosPreviews"
        :isEdit="isEdit"
        @allPhotosChanged="allPhotos = $event"
        @photosToDeleteChanged="photosToDelete = $event"
      />

      <SoundInput
        class="mb-2"
        :soundsErrors="soundsErrors"
        :existingSoundsPreviews="existingSoundsPreviews"
        :isEdit="isEdit"
        @allSoundsChanged="allSounds = $event"
        @soundsToDeleteChanged="soundsToDelete = $event"
      />

      <VideoInput
        class="mb-2"
        :videoInputs="$v.videos.$each.$iter"
        :isEdit="isEdit"
        :v="$v"
        :videosLength="videos.length"
        @addVideo="videos.push({ video: '', hasSound: false })"
        @deleteVideoInput="deleteVideoInput($event.index, $event.input)"
      />

      <div
        class="body-2 error--text my-5 font-weight-medium"
        v-if="
          $v.$dirty &&
          (!$v.allPhotos.atLeastOneAssetValidator ||
            !$v.videos.atLeastOneAssetValidator)
        "
      >
        Μια ενότητα πρέπει να έχει τουλάχιστον ένα αρχείο εικόνας ή βίντεο
      </div>

      <div
        class="body-2 error--text my-5 font-weight-medium"
        v-if="$v.$dirty && !$v.videos.vimeoValidator"
      >
        Ένα ή περισσότερα βίντεο δεν είναι Vimeo URLs.
      </div>

      <div class="d-flex align-center justify-center mt-6">
        <v-btn
          class="mr-4"
          color="success"
          small
          type="submit"
          :loading="loader"
          >Αποθηκευση</v-btn
        >

        <v-btn
          color="warning"
          small
          type="button"
          @click="$emit('closeSection')"
          >Ακυρωση</v-btn
        >
      </div>

      <DialogLoader
        v-if="loader"
        :open="loader"
        :text="'Ανέβασμα αρχείων. Παρακαλούμε μην κλείσετε την εφαρμογή.'"
      />
    </form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import DialogLoader from "@/components/DialogLoader";
import { io } from "socket.io-client";
import PhotoInput from "@/views/program/step2/PhotoInput";
import SoundInput from "@/views/program/step2/SoundInput";
import VideoInput from "@/views/program/step2/VideoInput";

// ============ VALIDATORS ============

const uniqueTitleValidator = function () {
  return !this.hasUniqueTitleValidatorError;
};

const sizeValidator = function (size) {
  return function (files) {
    let isValid = true;

    files.forEach((file) => {
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > size) isValid = false;
    });

    return isValid;
  };
};

const atLeastOneAssetValidator = function () {
  const hasVideos = !!this.videos.length;
  const hasPhotos = this.isEdit
    ? !!this.allPhotos.length || !!this.existingPhotosPreviews.length
    : !!this.allPhotos.length;

  return !(!hasPhotos && !hasVideos);
};

const vimeoValidator = function () {
  return !this.hasVimeoValidatorError;
};

// ============ END VALIDATORS ============

export default {
  props: ["isEdit", "section"],
  components: {
    DialogLoader,
    PhotoInput,
    SoundInput,
    VideoInput,
  },
  data() {
    return {
      title: "",
      hasUniqueTitleValidatorError: false,
      allPhotos: [],
      photosToDelete: [],
      allSounds: [],
      soundsToDelete: [],
      videos: [],
      videosToDelete: [],
      hasVimeoValidatorError: false,
      loader: false,
      socket: null,
    };
  },
  validations: {
    title: { required, uniqueTitleValidator },
    allPhotos: {
      atLeastOneAssetValidator,
      imageSizeValidator: sizeValidator(2.5),
    },
    allSounds: { soundSizeValidator: sizeValidator(8) },
    videos: {
      atLeastOneAssetValidator,
      vimeoValidator,
      $each: {
        video: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapState({
      currentProgram: (state) => state.admin.currentProgram,
    }),
    existingPhotosPreviews() {
      if (!this.isEdit || !this.section) return [];

      return this.section.sectionAssets
        .filter(
          (asset) =>
            asset.type == "image" &&
            !this.photosToDelete.find((photo) => photo.id == asset.id)
        )
        .map((asset) => {
          return {
            id: asset.id,
            src: `${process.env.VUE_APP_BASE_URL}/${asset.path}`,
            path: asset.path,
            thumb: asset.thumb,
          };
        });
    },
    existingSoundsPreviews() {
      if (!this.isEdit || !this.section) return [];

      return this.section.sectionAssets
        .filter(
          (asset) =>
            asset.type == "sound" &&
            !this.soundsToDelete.find((sound) => sound.id == asset.id)
        )
        .map((asset) => {
          return {
            id: asset.id,
            src: `${process.env.VUE_APP_BASE_URL}/${asset.path}`,
            path: asset.path,
          };
        });
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      if (!this.$v.title.required) errors.push("Ο τίτλος ειναι υποχρεωτικός.");
      if (!this.$v.title.uniqueTitleValidator)
        errors.push("Υπάρχει ήδη ενότητα με αυτόν τον τίτλο.");
      return errors;
    },
    photosErrors() {
      const errors = [];
      if (!this.$v.allPhotos.$dirty) return errors;
      if (!this.$v.allPhotos.imageSizeValidator)
        errors.push("Ένα ή περισσότερα αρχεία είναι άνω των 2.5MB.");
      return errors;
    },
    soundsErrors() {
      const errors = [];
      if (!this.$v.allSounds.$dirty) return errors;
      if (!this.$v.allSounds.soundSizeValidator)
        errors.push("Ένα ή περισσότερα αρχεία είναι άνω των 8MB.");
      return errors;
    },
    videosErrors() {
      const errors = [];
      if (!this.$v.videos.$dirty) return errors;

      return errors;
    },
  },
  created() {
    this.socket = io(process.env.VUE_APP_BASE_URL);

    //====== SOCKET SUCCESS RESPONSE ======
    this.socket.on("success", async (payload) => {
      // console.log(payload);

      let soundsError = false;

      if (this.allSounds.length || this.soundsToDelete.length) {
        try {
          const formData = new FormData();

          formData.set("programId", payload.section.programId);
          formData.set("sectionId", payload.section.id);

          this.allSounds.forEach((sound) => {
            formData.append("sounds", sound);
          });

          this.soundsToDelete.forEach((sound) => {
            formData.append("soundsToDelete[]", JSON.stringify(sound));
          });

          await this.sectionSounds(formData);
        } catch (e) {
          soundsError = true;
          console.log(e);
        }
      }

      if (payload.action == "new") {
        this.$store.commit("newSection", payload.section);

        this.toggleSnackbar({
          open: true,
          text: "Προστέθηκε νέα ενότητα!",
          color: "success",
        });
      } else {
        if (
          (!this.allSounds.length && !this.soundsToDelete.length) ||
          soundsError
        ) {
          this.$store.commit("editSection", payload.section);
        }

        this.toggleSnackbar({
          open: true,
          text: `Η ενότητα "${payload.section.title}" έχει ενημερωθεί`,
          color: "success",
        });
      }

      this.loader = false;
      this.$emit("closeSection");
    });

    //====== SOCKET ERROR RESPONSE ======
    this.socket.on("error", (payload) => {
      this.toggleSnackbar({
        open: true,
        text: "Κάτι πήγε στραβά. Παρακαλούμε δοκιμάστε ξανά αργότερα.",
        color: "error",
      });

      this.loader = false;
      this.$emit("closeSection");
    });

    //====== NEW SECTION INIT ======
    if (this.isEdit) {
      this.title = this.section?.title;

      this.videos = this.section.sectionAssets
        .filter((asset) => asset.type == "video")
        .map((asset) => {
          return {
            video: asset.path,
            hasSound: asset.hasSound,
            thumb: asset.thumb,
            id: asset.id,
          };
        });
    }
  },
  methods: {
    ...mapActions(["newSection", "editSection", "sectionSounds"]),
    ...mapMutations(["toggleSnackbar"]),
    deleteVideoInput(index, video) {
      if (this.isEdit && video.id) {
        this.videosToDelete.push({ id: video.id });
      }

      this.videos.splice(index, 1);
    },
    //SUBMIT
    async submit() {
      this.hasVimeoValidatorError = false;
      this.hasUniqueTitleValidatorError = false;

      this.$v.$touch();
      if (this.$v.$invalid) return;

      const formData = new FormData();

      this.loader = true;

      if (!this.isEdit) {
        formData.set("order", this.currentProgram.sections.length + 1);
        formData.set("title", this.title);
        formData.set("programId", this.currentProgram.id);
        formData.set("socketId", this.socket.id);

        this.allPhotos.forEach((photo) => {
          formData.append("sectionAssets", photo);
        });

        this.videos.forEach((video) => {
          if (video.video) {
            formData.append("videos[]", JSON.stringify(video));
          }
        });

        try {
          await this.newSection(formData);
        } catch (e) {
          if (e.response.status == 400) {
            if (e.response.data.data.title)
              this.hasUniqueTitleValidatorError = true;
            if (e.response.data.data.videos) this.hasVimeoValidatorError = true;
          }

          this.loader = false;
        }
      } else {
        formData.set("title", this.title);
        formData.set("programId", this.currentProgram.id);
        formData.set("sectionId", this.section.id);
        formData.set("socketId", this.socket.id);

        this.allPhotos.forEach((photo) => {
          formData.append("sectionAssets", photo);
        });

        this.videos.forEach((video) => {
          if (video.video) {
            formData.append("videos[]", JSON.stringify(video));
          }
        });

        this.photosToDelete.forEach((photo) => {
          formData.append("photosToDelete[]", JSON.stringify(photo));
        });

        this.videosToDelete.forEach((video) => {
          formData.append("videosToDelete[]", JSON.stringify(video));
        });

        try {
          await this.editSection(formData);
        } catch (e) {
          console.log(e);

          if (e.response.status == 400) {
            if (e.response.data.data.title)
              this.hasUniqueTitleValidatorError = true;
            if (e.response.data.data.videos) this.hasVimeoValidatorError = true;
          }

          this.loader = false;
        }
      }
    },
  },
  watch: {
    videos: {
      deep: true,
      handler() {
        this.hasVimeoValidatorError = false;
      },
    },
    title() {
      this.hasUniqueTitleValidatorError = false;
    },
  },
  destroyed() {
    this.socket.close();
  },
};
</script>

<style lang="scss" scoped>
.new-section-wrapper {
  overflow: hidden;
}
</style>