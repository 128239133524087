<template>
  <div class="component-wrapper inner d-flex flex-column align-center">
    <v-btn icon color="primary" @click="onClose" class="close-button">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="body-2 text-center mb-2" v-if="role == 'superuser'">
      ({{ activeMuseum.museumLocales[0].title }})
    </div>

    <div class="text-h6 primary--text text-center mb-1">Βήμα 3</div>

    <div class="text-h6 text-center title-underline mb-12">
      Δημιουργία Σεναρίου
    </div>

    <div class="inner-wrapper d-flex flex-column">
      <Step1Info class="mb-8" />

      <div class="subtitle-1 font-weight-medium mb-4">Ενότητες</div>

      <draggable v-model="sections" :animation="200">
        <transition-group>
          <div
            class="pa-2 primary mb-2 rounded white--text d-flex align-center"
            :class="{
              primary: openSection && openSection.id == section.id,
              'warning lighten-1': openSection && openSection.id == section.id,
            }"
            v-for="section in sections"
            :key="section.id"
          >
            <div class="body-1">{{ section.order }}. {{ section.title }}</div>

            <v-btn
              small
              class="ml-auto"
              @click="setOpenSection(null)"
              v-if="openSection && openSection.id == section.id"
            >
              <v-icon left>mdi-close</v-icon>
              Κλεισιμο
            </v-btn>

            <v-btn
              small
              class="ml-auto"
              @click="setOpenSection(section)"
              v-else
            >
              <v-icon left>mdi-arrow-expand</v-icon>
              Ανοιγμα
            </v-btn>
          </div>
        </transition-group>
      </draggable>

      <template v-if="openSection">
        <div class="assets-wrapper mt-10">
          <draggable v-model="openSectionAssets" :animation="200">
            <div
              v-for="asset in openSectionAssets"
              :key="asset.id"
              class="item"
            >
              <div class="item-content-wrapper rounded">
                <div class="item-actions-wrapper d-flex align-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-checkbox
                          class="ma-0 pa-0 reorder-checkbox"
                          hide-details
                          :ripple="false"
                          v-model="asset.presentationInclude"
                          @change="onUpdateSectionAssetsOrder"
                        >
                        </v-checkbox>
                      </div>
                    </template>

                    <div class="body-2">Συμπεριλάβετε στην παρουσίαση</div>
                  </v-tooltip>

                  <v-tooltip top v-if="asset.type == 'video'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="has-sound-icon rounded-bl primary ml-auto"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          asset.hasSound
                            ? "mdi-music-note"
                            : "mdi-music-note-off"
                        }}
                      </v-icon>
                    </template>

                    <span>{{
                      asset.hasSound
                        ? "Το βίντεο έχει ήχο"
                        : "Το βίντεο είναι σε σίγαση"
                    }}</span>
                  </v-tooltip>
                </div>

                <img
                  :src="`${baseUrl}/${asset.path}`"
                  v-if="asset.type == 'image'"
                  class="item-content"
                />

                <div
                  class="item-content video"
                  v-if="asset.type == 'video'"
                  @click="openVideo(asset.path)"
                >
                  <img :src="asset.thumb" />
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </template>

      <v-btn
        color="primary"
        class="align-self-center mt-10"
        :loading="loader"
        @click="onSave"
        :disabled="!hasChanges"
      >
        <v-icon left>mdi-content-save</v-icon>
        Αποθηκευση
      </v-btn>

      <v-divider class="my-10"></v-divider>

      <div class="d-flex">
        <v-btn color="primary" small @click="onPrev">
          <v-icon left>mdi-chevron-left</v-icon>

          Προηγουμενο
        </v-btn>

        <v-btn
          class="ml-auto"
          color="primary"
          small
          @click="onFinish"
          :disabled="hasChanges"
          :loading="submitLoader"
        >
          {{
            isEdit && !currentProgram.isDraft
              ? "Ολοκληρωση"
              : "υποβολη προγραμματος"
          }}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState, mapActions, mapMutations } from "vuex";
import Step1Info from "@/views/program/step1/Step1Info";

export default {
  components: {
    draggable,
    Step1Info,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      loader: false,
      submitLoader: false,
      initialData: null,
    };
  },
  computed: {
    ...mapState({
      openSection: (state) => state.admin.openSection,
      currentProgram: (state) => state.admin.currentProgram,
      role: (state) => state.auth.role,
      activeMuseum: (state) => state.superuser.activeMuseum,
    }),
    isEdit() {
      return this.$route.path.includes("edit");
    },
    sections: {
      get() {
        return this.$store.state.admin.currentProgram.sections;
      },
      set(value) {
        this.updateSectionsOrder(value);
      },
    },
    openSectionAssets: {
      get() {
        return this.$store.state.admin.openSection.sectionAssets.filter(
          (asset) => asset.type != "sound"
        );
      },
      set(value) {
        this.updateSectionAssetsOrder(value);
      },
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !==
        JSON.stringify({ ...this.currentProgram })
      );
    },
  },
  created() {
    this.initialData = JSON.parse(JSON.stringify(this.currentProgram));
  },
  methods: {
    ...mapMutations([
      "updateSectionsOrder",
      "setOpenSection",
      "updateSectionAssetsOrder",
    ]),
    ...mapActions(["reorder", "submitNewProgram"]),
    openVideo(path) {
      window.open(path);
    },
    async onSave() {
      this.loader = true;

      try {
        await this.reorder();

        this.initialData = this.initialData = JSON.parse(
          JSON.stringify(this.currentProgram)
        );

        this.loader = false;
      } catch (e) {
        console.log(e);
        this.loader = false;
      }
    },
    onClose() {
      if (this.role == "admin") {
        this.$router.push({ path: "/" });
      } else {
        this.$router.push({
          path: `/superuser/museum/${this.activeMuseum.id}/programs`,
        });
      }
    },
    onPrev() {
      let path;

      if (this.isEdit) {
        if (this.role == "admin") {
          path = `/program/edit/${this.currentProgram.uniqueCode}/step2`;
        } else {
          path = `/superuser/museum/${this.activeMuseum.id}/program/edit/${this.currentProgram.uniqueCode}/step2`;
        }
      } else {
        if (this.role == "admin") {
          path = `/program/new/step2`;
        } else {
          path = `/superuser/museum/${this.activeMuseum.id}/program/new/step2`;
        }
      }

      this.$router.push({
        path,
      });
    },
    async onFinish() {
      if (this.isEdit && !this.currentProgram.isDraft) {
        this.onClose();
        return;
      }

      this.submitLoader = true;

      try {
        await this.submitNewProgram();
        this.onClose();
      } catch (e) {}

      this.submitLoader = false;
    },
    onUpdateSectionAssetsOrder() {
      this.updateSectionAssetsOrder(this.openSectionAssets);
    },
  },
  destroyed() {
    this.setOpenSection(null);
  },
};
</script>

<style lang="scss" scoped>
.assets-wrapper > div {
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: 25%;
  padding: 4px;
}

.item-content-wrapper {
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  border: 1px solid #e0e0e0;
}

.item-actions-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: rgba(#fff, 0.5);
  height: 24px;
  padding-left: 4px;

  .has-sound-icon {
    color: white;
    padding: 1px;
  }
}

.item-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: grabbing;

  &.video {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>