<template>
  <div>
    <div
      class="video-wrapper d-flex"
      v-for="(input, index) in videoInputs"
      :key="index"
    >
      <div
        class="new-video-badge pa-1 success"
        v-if="isEdit && !input.$model.id"
      >
        <div class="white--text text-center">Νέο</div>
      </div>

      <v-text-field
        outlined
        dense
        prepend-inner-icon="mdi-video"
        label="Βίντεο"
        hint="Εισάγετε url απο vimeo video"
        persistent-hint
        v-model="input.$model.video"
        :error="v.$dirty && !v.videos.vimeoValidator"
        :error-messages="
          v.$dirty && !input.video.required
            ? 'Το url του βίντεο είναι υποχρεωτικό'
            : ''
        "
        :disabled="!!input.$model.id"
      ></v-text-field>

      <img
        :src="input.$model.thumb"
        class="video-thumb rounded ml-5"
        v-if="input.$model.id"
      />

      <div class="d-flex flex-column ml-5">
        <v-checkbox
          dense
          hide-details
          class="my-0"
          :ripple="false"
          labe="Ήχος"
          v-model="input.$model.hasSound"
          :error="
            v.$dirty && (!input.video.required || !v.videos.vimeoValidator)
          "
        ></v-checkbox>

        <div
          class="caption grey--text text--darken-1"
          :class="{
            'error--text':
              v.$dirty && (!input.video.required || !v.videos.vimeoValidator),
          }"
        >
          Ηχος
        </div>
      </div>

      <v-divider
        vertical
        class="mx-5 mt-2 mb-4"
        v-if="videosLength"
      ></v-divider>

      <v-btn
        icon
        color="error"
        v-if="videosLength"
        @click="$emit('deleteVideoInput', { index, input: input.$model })"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>

    <v-btn color="primary" x-small @click="$emit('addVideo')">
      <v-icon left>mdi-plus</v-icon>
      Προσθηκη βιντεο
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["videoInputs", "isEdit", "v", "videosLength"],
};
</script>

<style lang="scss" scoped>
.video-thumb {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.video-wrapper {
  position: relative;
}

.new-video-badge {
  position: absolute;
  top: -15px;
  left: -15px;
  border-radius: 50%;
  z-index: 1;
  font-size: 10px;
}
</style>