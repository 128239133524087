<template>
  <div class="component-wrapper d-flex flex-column align-center pa-5">
    <form
      @submit.prevent="submit"
      class="d-flex flex-column mt-auto my-auto form"
      novalidate
      autocomplete="off"
    >
      <v-text-field
        label="Κωδικός προγράμματος"
        outlined
        class="flex-grow-0 mb-2"
        dense
        type="email"
        v-model="roomName"
        color="primary"
        :error-messages="roomNameErrors"
      ></v-text-field>

      <v-btn
        color="primary"
        class="align-self-center"
        depressed
        type="submit"
        :loading="loader"
        :disabled="!isSupported"
        >Εναρξη Παρακολουθησης</v-btn
      >
    </form>

    <v-alert dense text type="error" v-if="!isSupported">
      Το πρόγραμμα περιήγησής σας (Web Browser) δεν υποστηρίζει το πρωτοκολλο
      που χρησιμοποιείται για την τηλε-παρουσίαση
    </v-alert>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { isSupported } from "twilio-video";

export default {
  data() {
    return {
      roomName: "",
      loader: false,
      isSupported,
    };
  },
  validations: {
    roomName: { required },
  },
  computed: {
    roomNameErrors() {
      const errors = [];
      if (!this.$v.roomName.$dirty) return errors;
      if (!this.$v.roomName.required)
        errors.push("Αυτό το πεδίο είναι απαραίτητο");
      return errors;
    },
  },
  methods: {
    ...mapActions(["connectToRoom"]),
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loader = true;
        await this.connectToRoom(this.roomName);
        this.loader = false;
        this.$router.replace({ path: "/room" });
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 30px;
  cursor: pointer;
}

.form {
  width: 400px;
}
</style>
